// Common styles that can be shared between components
const commonStyles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '20px',
    },
    logoContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center', 
      width: '100%',
    },
    logo: {
      height: 60,
      width: 'auto',
    },
    submitButton: {
      minWidth: '100%',
      minHeight: 45,
      background: '#1C1D1F',
      borderRadius: '4px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 8,
      textTransform: 'none',
      color: 'white',
      cursor: 'pointer',
      marginTop: '15px',
    },
    heading: {
      padding: '10px 0px',
      color: 'black',
      textAlign: 'left',
      fontSize: '32px',
      fontWeight: '400',
      fontFamily: "'EB Garamond', serif",
      width: '100%',
    },
    description: {
      textAlign: 'left',
      fontSize: '14px',
      fontFamily: 'Manrope',
      fontWeight: 400,
      width: '100%',
    },
    underlineLink: {
      textDecoration: 'underline',
      cursor: 'pointer',
      fontWeight: 600,
    },
  };
  
  // Styles specific to the SignUp component
  export const signUpStyles = {
    ...commonStyles,
    container: {
      ...commonStyles.container,
      justifyContent: 'center',
      position: 'relative',
    },
    scrollableContent: {
      flex: 1,
      overflowY: 'auto',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    logoContainer: {
      ...commonStyles.logoContainer,
      justifyContent: 'space-between',
      padding: '0px 0',
    },
    chevronIcon: {
      fontSize: '24px',
      cursor: 'pointer',
      color: 'black',
    },
    emptyRightHeader: {
      width: '24px',
    },
    contentSection: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '20px',
      width: '100%',
      maxWidth: '600px',
      borderRadius: '8px',
    },
    modalOverlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-end',
      zIndex: 1000,
    },
    modalContent: {
      background: 'white',
      borderTopLeftRadius: '16px',
      borderTopRightRadius: '16px',
      width: '100%',
      maxWidth: '1000px',
      padding: '30px',
      position: 'relative',
    },
    closeIcon: {
      cursor: 'pointer',
      color: 'gray',
      fontSize: '24px',
    },
    popupHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '20px',
    },
    bulletContainer: {
      width: '100%',
      marginTop: '16px',
      marginBottom: '16px',
    },
    bulletItem: {
      display: 'flex',
      alignItems: 'flex-start',
      marginBottom: '12px',
    },
    bulletIcon: {
      color: 'black',
      marginRight: '8px',
      minWidth: '24px',
    },
    bulletText: {
      color: '#555555',
      fontSize: '15px',
      fontFamily: 'Manrope',
      fontWeight: 600,
      lineHeight: '20px',
    },
    priceText: {
      textAlign: 'left',
      fontSize: '16px',
      fontFamily: 'Manrope',
      fontWeight: 600,
      width: '100%',
    },
    buttonContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    footerText: {
      marginTop: '16px',
      textAlign: 'left',
      fontSize: '14px',
      fontFamily: 'Manrope',
      width: '100%',
      fontWeight: 600,
    },
    modal: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'center',
    },
    modalDescription: {
      ...commonStyles.description,
      marginBottom: '20px',
    },
  };
  
  // Define modalButton after submitButton to use its styles
  signUpStyles.modalButton = {
    ...commonStyles.submitButton,
    fontWeight: 500,
  };
  
  // Styles specific to the Login component
  export const loginStyles = {
    ...commonStyles,
    background: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      zIndex: -1,
    },
    header: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    leftHeader: {
      width: '40px', // Set a fixed width for balance
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    centerHeader: {
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    rightHeader: {
      width: '40px', // Set a fixed width for balance
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    logo: {
      ...commonStyles.logo,
      zIndex: 2,
    },
    formSection: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '20px',
      width: '100%',
      maxWidth: '400px',
      borderRadius: '8px',
    },
    form: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
    },
    textField: {
      background: '#FFFFFF',
      borderRadius: '8px',
      overflow: 'hidden',
      fontFamily: 'Manrope',
      fontWeight: 'normal',
      fontSize: '1rem',
      marginBottom: '16px',
      width: '100%',
    },
    secondaryButton: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: '#FFFFFF',
      borderRadius: '4px',
      border: 0,
      color: '#1C1D1F',
      fontSize: 16,
      fontFamily: 'Manrope',
      fontWeight: 600,
      padding: '10px 8px',
      height: 40,
      textTransform: 'none',
      cursor: 'pointer',
      marginBottom: '15px',
    },
    goBackButton: {
      marginRight: 'auto',
      color: 'black',
      fontSize: '16px',
      fontFamily: 'Manrope',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    errorMessage: {
      maxWidth: '100%',
      textAlign: 'left',
      fontSize: 16,
      fontFamily: 'Manrope',
      fontWeight: '500',
      wordWrap: 'break-word',
      color: 'red',
      marginTop: '10px',
    },
    landingContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '20px',
      width: '100%',
      maxWidth: '400px',
      borderRadius: '8px',
      textAlign: 'center',
      flex: 1,
      justifyContent: 'flex-end',
    },
    mainContentWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      flexGrow: 1,
      width: '100%',
      maxWidth: '500px',
    },
    landingOverlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
      zIndex: 1,
    },
    landingContent: {
      zIndex: 2,
    },
    landingTitle: {
      ...commonStyles.heading,
      color: '#FFFFF7',
    },
    landingParagraph: {
      margin: '20px 0px',
      textAlign: 'left',
      color: '#F5F3F0',
      fontSize: '14px',
      fontFamily: 'Manrope',
      width: '100%',
    },
    landingQuestion: {
      margin: '8px 0px',
      textAlign: 'left',
      color: '#F5F3F0',
      fontSize: '14px',
      fontWeight: 500,
      fontFamily: 'Manrope',
      width: '100%',
    },
    landingFooter: {
      textAlign: 'left',
      color: '#F5F3F0',
      fontSize: '14px',
      fontWeight: 500,
      fontFamily: 'Manrope',
      width: '100%',
    },
    familyIDTitle: {
      ...commonStyles.heading,
      color: 'black',
    },
    familyIDDescription: {
      marginTop: '16px',
      ...commonStyles.description,
      color: '#555555',
    },
    memberSelectionContainer: {
      width: '100%',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      gap: 12,
      display: 'flex',
      padding: '20px',
      maxWidth: '400px',
      borderRadius: '8px',
    },
    memberSelectionHeader: {
      ...commonStyles.heading,
      fontSize: '32px',
    },
    memberSelectionDescription: {
      textAlign: 'left',
      color: 'black',
      fontSize: 14,
      fontFamily: 'Manrope',
      lineHeight: '20px',
      fontWeight: 600,
      wordWrap: 'break-word',
      marginBottom: '10px',
      width: '100%',
    },
    memberSelectionFooter: {
      textAlign: 'left',
      color: 'black',
      fontSize: 14,
      fontFamily: 'Manrope',
      lineHeight: '20px',
      fontWeight: 400,
      wordWrap: 'break-word',
      marginBottom: '20px',
      width: '100%',
    },
    memberSelectionButtonContainer: {
      width: '100%',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 12,
      display: 'flex',
    },
    memberSelectionButton: {
      width: '100%',
      maxWidth: 400,
      height: 50,
      padding: '10px 8px',
      background: '#1C1D1F',
      borderRadius: 12,
      border: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'white',
      fontSize: 16,
      fontFamily: 'Manrope',
      wordWrap: 'break-word',
      cursor: 'pointer',
    },
    loginFormHeader: {
      ...commonStyles.heading,
      color: 'black',
      fontSize: '32px',
    },
    loginFormSubheader: {
      ...commonStyles.description,
      color: 'black',
      marginBottom: '20px',
    },
    loginFormForgotPassword: {
      color: 'black',
      fontSize: '14px',
      fontWeight: '400',
      fontFamily: 'Manrope',
      margin: '20px 0px',
      textAlign: 'left',
      width: '100%',
    },
  };
  