import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, CircularProgress } from '@mui/material';

const secureURL = process.env.REACT_APP_SECURE_URL;
const secureKey = process.env.REACT_APP_UPLOAD_API_KEY;

class FileViewDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fileContent: null,
            fileType: '',
            textContent: '',
            displayName: '',
            jsonData: null,
            isLoading: false,
        };
    }

    componentDidMount() {
        if (this.props.fileName) {
            this.fetchFileContent(this.props.fileName);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.fileName !== this.props.fileName) {
            if (this.props.fileName) {
                this.fetchFileContent(this.props.fileName);
            } else {
                this.setState({
                    fileContent: null,
                    fileType: '',
                    textContent: '',
                    displayName: '',
                    jsonData: null,
                    isLoading: false,
                });
            }
        }
    }

    fetchFileContent = (fileName) => {
        const { familyId, category, subCategory } = this.props;

        if (!fileName) {
            return;
        }

        this.setState({ isLoading: true });

        const url = `${secureURL}/fileupload/digiLocker.php?filename=${fileName}&familyid=${familyId}&category=${category}&subcategory=${subCategory}`;
        fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${secureKey}`,
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.blob();
            })
            .then(blob => {
                let fileType = blob.type;
                if (!fileType) {
                    // Fallback if blob.type is empty
                    if (fileName.endsWith('.json')) {
                        fileType = 'application/json';
                    } else if (fileName.endsWith('.txt')) {
                        fileType = 'text/plain';
                    } else if (fileName.endsWith('.pdf')) {
                        fileType = 'application/pdf';
                    }
                }
                this.setState({ fileContent: blob, fileType }, () => {
                    if (fileType.startsWith('text/') || fileType === 'application/json') {
                        const reader = new FileReader();
                        reader.onload = () => {
                            const text = reader.result;
                            if (fileType === 'application/json') {
                                try {
                                    const jsonData = JSON.parse(text);
                                    if (jsonData.textField && typeof jsonData.textField === 'string') {
                                        jsonData.textField = JSON.parse(jsonData.textField);
                                    }
                                    // Set displayName
                                    const textField = jsonData.textField || {};
                                    const fileCategory = textField.subcategory || '';
                                    const name = textField.name || 'Details';
                                    const displayName = `${fileCategory} - ${name}`;
                                    this.setState({ jsonData, textContent: text, displayName, isLoading: false });
                                } catch (error) {
                                    console.error('Error parsing JSON:', error);
                                    this.setState({ textContent: text, displayName: 'Data', isLoading: false });
                                }
                            } else {
                                this.setState({ textContent: text, isLoading: false });
                            }
                        };
                        reader.readAsText(blob);
                    } else {
                        this.setState({ isLoading: false });
                    }
                });
            })
            .catch(error => {
                console.error('Error fetching file:', error);
                this.setState({ isLoading: false });
            });
    };

    handleFileNameClick = (fileName) => {
        this.fetchFileContent(fileName);
    };

    downloadFile = () => {
        const { fileContent, displayName } = this.state;
        const downloadFileName = displayName || 'download';
        const downloadUrl = window.URL.createObjectURL(fileContent);
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.download = downloadFileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
        URL.revokeObjectURL(downloadUrl);
    };

    render() {
        const { isOpen, onClose } = this.props;
        const { fileContent, fileType, textContent, displayName, jsonData, isLoading } = this.state;

        if (!isOpen) {
            return null;
        }

        let dialogTitle = displayName || 'Attachment';

        let contentDisplay;

        if (isLoading) {
            contentDisplay = (
                <div style={{ textAlign: 'center', padding: '20px' }}>
                    <CircularProgress style={{color:'black'}} />
                </div>
            );
        } else if (!fileContent) {
            contentDisplay = (
                <Typography variant="body1" align="center">
                    No content available.
                </Typography>
            );
        } else if (fileType.startsWith('image/')) {
            const imageUrl = URL.createObjectURL(fileContent);
            contentDisplay = (
                <img src={imageUrl} alt={displayName} style={{ maxWidth: '100%' }} />
            );
        } else if (fileType === 'application/pdf') {
            const pdfUrl = URL.createObjectURL(fileContent);
            contentDisplay = (
                <iframe src={pdfUrl} width="100%" height="600px" title={displayName} />
            );
        } else if (fileType.startsWith('text/') || fileType === 'application/json') {
            if (!textContent) {
                contentDisplay = (
                    <Typography variant="body1" align="center">
                        Loading content...
                    </Typography>
                );
            } else if (fileType === 'application/json' && jsonData) {
                console.log(jsonData)
                const renderJsonData = (data) => {
                    return Object.entries(data).map(([key, value]) => {
                        if (key === 'category' || key === 'subcategory') {
                            return null;
                        }
                
                        if (key === 'fileName') {
                            if (value) {
                                return (
                                    <div key={key} style={{ marginLeft: '20px' }}>
                                        <Button
                                            onClick={() => this.handleFileNameClick(value)}
                                            color="primary"
                                            style={{ 
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                marginBottom: '10px',
                                                cursor: 'pointer',
                                                padding:'5px',
                                                backgroundColor:'#F5F3F0' ,
                                                borderRadius:'15px'
                                            }}
                                        >
                                            Attachment
                                        </Button>
                                    </div>
                                );
                            } else {
                                return null;
                            }
                        }
                
                   
                        if (typeof value === 'object' && value !== null) {
                            return (
                                <div key={key} style={{ marginLeft: '20px' }}>
                                    <strong>{key}:</strong>
                                    {renderJsonData(value)}
                                </div>
                            );
                        }
                
                        return (
                            <div key={key}>
                                <strong>{key}:</strong> {value}
                            </div>
                        );
                    });
                };
                 

                contentDisplay = (
                    <div style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                        {renderJsonData(jsonData)}
                    </div>
                );
            } else {
                contentDisplay = (
                    <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                        {textContent}
                    </pre>
                );
            }
        } else {
            contentDisplay = <Typography>Preview not available for this file type.</Typography>;
        }

        return (
            <Dialog
                open={isOpen}
                onClose={onClose}
                fullWidth
                maxWidth="md"
                PaperProps={{
                    style: {
                        backgroundColor: '#E8E5E0',
                        color: '#1C1D1F',
                        borderRadius: '15px',
                    },
                }}
            >
                <DialogTitle>{dialogTitle}</DialogTitle>
                <DialogContent>{contentDisplay}</DialogContent>
                <DialogActions>
                    {!isLoading && fileContent && (
                        <Button onClick={this.downloadFile} color="primary">
                            Download
                        </Button>
                    )}
                    <Button onClick={onClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default FileViewDialog;
