import {
    createTheme
} from "@mui/material";

const MUIStyles = createTheme({
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    backgroundColor: "#2F98A3", color: "#fff", "&:hover": {
                        backgroundColor: "#2F98A3",
                    },
                },
            },
        }, MuiFab: {
            styleOverrides: {
                root: {
                    boxShadow: "none",
                },
            },
         }, MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: "#FFFFFF",
                },
            },
        }, MuiTextField: {
            styleOverrides: {
                root: {
                    "& .MuiInputLabel-shrink": {
                        color: "#888888",
                    },
                },
            },
        }, MuiInputBase: {
            styleOverrides: {
                input: {
                    color: "#888888",
                },
            },
        }, MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "transparent",
                    }, "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "transparent",
                    },
                },
            },
        },
    },
});

export default MUIStyles;