import React from 'react';
import {
    Typography, Paper, List, ListItem, ListItemText, Badge, Grid, IconButton
} from "@mui/material";
import { AppContext } from './AppContext';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';
import { ChevronLeft } from '@mui/icons-material';

const styles = {
    gridItemCenterAligned: {
        padding: 12,
        display: 'flex',
        justifyContent: 'center',
    },
    linkStyle: {
        color: '#1C1D1F',
    },
    titleTypography: {
        textAlign: 'center',
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: 600,
        color: '#1C1D1F',
    },
    noNotificationsText: {
        textAlign: 'center',
        marginTop: 20,
        color: 'grey',
        justifyContent: 'center',
    },
    listWrapper: {
        maxHeight: '90vh',
        overflowY: 'auto',
    },
    notificationPaper: {
        backgroundColor: 'white',
        margin: '10px 18px',
        borderRadius: 4,
    },
    messageText: {
        color: '#1C1D1F',
    },
    dateText: {
        color: '#9e9e9e',
    },
    closeButton: {
        color: '#1C1D1F',
    },
    badgeSx: {
        '& .MuiBadge-dot': { backgroundColor: 'black' },
        margin: '16px 16px 16px 4px',
    },
    noTasksContainer: {
        textAlign: 'center',
        marginTop: 20,
        padding: '0px 16px',
    },
    noTasksTypography: {
        width: '100%',
        fontSize: '16px',
        color: '#000000',
        marginTop: '10px',
        fontFamily: "'Open Sans', sans-serif",
        background: '#F5F3F0',
        padding: '14px 16px',
        borderRadius: '8px',
    },
};

const MAKE_HIDDEN = 1;
const MARK_READ = 0;

class Notifications extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);

        this.state = {
            notificationData: [], gridHeight: 0,
        };
        this.gridRef = React.createRef();
    }

    componentDidMount() {
        this.updateGridHeight();
        window.addEventListener('resize', this.updateGridHeight);
    }

    componentDidUpdate() {
        this.updateGridHeight();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateGridHeight);
    }


    updateGridHeight = () => {
        if (this.gridRef.current) {
            const height = this.gridRef.current.clientHeight;
            if (height !== this.state.gridHeight) {
                this.setState({gridHeight: height});
            }
        }
    };
    clearNotificationFromServer = (id, clear) => {


        if (this.context.isWebSocketOpen) {
            const socket = this.context.ws;
            const familyId = localStorage.getItem('familyID');

            const notificationAcknowledgeRequest = {
                type: 'read_notification_acknowledgement',
                family_id: familyId,
                notification_id: id,
                is_hidden: clear,
            };
            socket.send(JSON.stringify(notificationAcknowledgeRequest));
        }
    };

    handleNotificationClose = (id) => {
        this.clearNotificationFromServer(id, MAKE_HIDDEN);
        const updatedNotifications = this.context.notificationData.filter(
            (notification) => notification.ID !== id
        );
        this.context.updateNotificationBadge(updatedNotifications);
    };

    handleNotificationClick = (id) => {
        this.clearNotificationFromServer(id, MARK_READ);
        const updatedNotifications = this.context.notificationData.map((notification) => {
            if (notification.ID === id) {
                return { ...notification, timestamp_read: true };
            }
            return notification;
        });

        this.context.updateNotificationBadge(updatedNotifications);
    };

    render() {
        let {notificationData} = this.context;

        function formatTimestamp(timestamp) {
            const date = new Date(timestamp * 1000);
            const now = new Date();

            const diff = now - date;

            const hours = Math.floor(diff / (1000 * 60 * 60));

            if (hours < 1) {
                return `${Math.floor(diff / (1000 * 60))} minutes ago`;
            } else if (hours < 24) {
                return `${hours} hours ago`;
            } else if (hours < 48) {
                return 'Yesterday';
            } else {
                return (
                    date.toDateString().slice(0, 3) +
                    ' ' +
                    date.getDate() +
                    ' ' +
                    date.toLocaleString('default', { month: 'short' })
                );
            }
        }

        return (
            <div>
                <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    overflow="hidden"
                    ref={this.gridRef}
                >
                    <Grid item xs={1} sm={1} style={styles.gridItemCenterAligned}>
                        <Link to={`/#`} style={styles.linkStyle}>
                            <ChevronLeft />
                        </Link>
                    </Grid>

                    <Grid item xs={10} sm={10} style={styles.gridItemCenterAligned}>
                        <Typography variant="h6" component="div" style={styles.titleTypography}>
                            Notifications
                        </Typography>
                    </Grid>

                    <Grid item xs={1} sm={1}></Grid>
                </Grid>

                {notificationData.length === 0 ? (
                     <div style={styles.noTasksContainer}>
                     <Typography style={styles.noTasksTypography}> No notifications</Typography>
                 </div>
                ) : (
                    <div style={styles.listWrapper}>
                        <List>
                            {notificationData.map((notification) => {
                                let displayDate = formatTimestamp(notification.timestamp);
                                return (
                                    <React.Fragment key={notification.ID}>
                                        <Paper
                                            onClick={() => this.handleNotificationClick(notification.ID)}
                                            style={styles.notificationPaper}
                                            elevation={0}
                                        >
                                            <ListItem>
                                                <Badge
                                                    variant="dot"
                                                    invisible={notification.timestamp_read}
                                                    sx={styles.badgeSx}
                                                ></Badge>
                                                <ListItemText
                                                    primary={
                                                        <Typography style={styles.messageText}>
                                                            {notification.message}
                                                        </Typography>
                                                    }
                                                    secondary={
                                                        <Typography style={styles.dateText}>
                                                            {displayDate}
                                                        </Typography>
                                                    }
                                                />
                                                <IconButton
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        this.handleNotificationClose(notification.ID);
                                                    }}
                                                    edge="end"
                                                    aria-label="delete"
                                                    size="small"
                                                    style={styles.closeButton}
                                                >
                                                    <CloseIcon />
                                                </IconButton>
                                            </ListItem>
                                        </Paper>
                                    </React.Fragment>
                                );
                            })}
                        </List>
                    </div>
                )}
            </div>
        );
    }
}

export default Notifications;
