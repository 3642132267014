import React from 'react';
import {Typography, Button, Grid,  CircularProgress } from "@mui/material";
import QRCode from 'qrcode.react';
import {Link} from 'react-router-dom';
import { ChevronLeft } from '@mui/icons-material';

const baseURL = process.env.REACT_APP_API_BASE_URL;

const styles = {
    gridItemHeader: {
        padding: 12,
        display: 'flex',
        justifyContent: 'center',
    },
    linkStyle: {
        color: 'black',
    },
    headingTypography: {
        textAlign: 'center',
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: '600',
        color: '#1C1D1F',
    },
    mainDivStyle: {
        display: 'flex',
        minHeight: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px',
    },
    gridContainerStyle: {
        textAlign: 'center',
    },
    gridItemContentStyle: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    contentTypography: {
        color: '#1C1D1F',
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: '400',
        marginBottom: 20,
    },
    referralLinkDiv: {
        color: '#1C1D1F',
        margin: '10px 0',
        wordBreak: 'break-all',
    },
    buttonStyle: {
        marginTop: 10,
        backgroundColor: '#1C1D1F',
        color: 'white',
    },
};

class Referral extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            referralLink: '', memberName: '', isLoading:true
        };
    }

    componentDidMount() {
        this.fetchMemberName();
    }

    fetchMemberName = () => {
        const memberID = localStorage.getItem("memberID");
        const familyID = localStorage.getItem("familyID");

        const requestOptions = {
            method: "POST", headers: {
                "Content-type": "application/json; charset=UTF-8",
            }, body: JSON.stringify({
                memberID: memberID
            }),
        };

        fetch(`${baseURL}/utils/fetchMemberData.php`, requestOptions)
            .then((response) => response.json())
            .then(data => {
                const memberName = data.First;
                this.setState({
                    memberName: memberName,
                    referralLink: `https://form.typeform.com/to/Qr930SAI#member_first_name=${encodeURIComponent(memberName)}&ref_family_id=${encodeURIComponent(familyID)}`,
                },()=>{
                    this.setState({isLoading:false})
                });
            })
            .catch(error =>{ 
                console.error('Error fetching member name:', error)
                this.setState({ isLoading: false });
            });
    }

    copyToClipboard = () => {
        navigator.clipboard.writeText(this.state.referralLink)
            .then(() => alert('Referral link copied to clipboard!'))
            .catch(err => console.error('Could not copy text: ', err));
    }

    render() {
        const {referralLink , isLoading } = this.state;

        return (<div>
            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item xs={1} sm={1} style={styles.gridItemHeader}>
                    <Link to={`/#`} style={styles.linkStyle}>
                        <ChevronLeft/>
                    </Link>
                </Grid>

                <Grid item xs={10} sm={10} style={styles.gridItemHeader}>
                    <Typography variant="h6" component="div" style={styles.headingTypography}>
                        Refer a Friend to BlckBx
                    </Typography>
                </Grid>

                <Grid item xs={1} sm={1}></Grid>
            </Grid>
            {isLoading ? (
                <div style={styles.mainDivStyle}>
                    <CircularProgress style={{color:'#1C1D1F'}} />
                </div>
            ) : (
                <div style={styles.mainDivStyle}>
                    <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        justifyContent="center"
                        style={styles.gridContainerStyle}
                    >
                        <Grid item xs={12} md={8} lg={6} style={styles.gridItemContentStyle}>
                            <Typography variant="h7" component="div" style={styles.contentTypography}>
                                Want to let your friends, family or colleagues try BlckBx as a free gift from you?
                                <br /> <br />
                                We will do one task for them for free to experience BlckBx.
                                <br /> <br />
                                Please ask them to scan your personal QR code below or copy and text them the link.
                            </Typography>
                            <QRCode value={referralLink} size={128} level={'H'} />
                            <div style={styles.referralLinkDiv}>{referralLink}</div>
                            <Button onClick={this.copyToClipboard} variant="contained" style={styles.buttonStyle}>
                                Copy Link
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            )}

        </div>);
    }
}

export default Referral;
