import React, { useContext } from 'react';
import { Tab, Tabs, Box, Button, Badge } from "@mui/material";
import AddTaskIcon from '@mui/icons-material/AddTask';
import {AppContext} from './AppContext';

const tabStyles = {
    container: {
        flex: 1, textAlign: 'center'
    }
};



const buttonStyles = {
    background: '#1C1D1F',
    borderRadius: '15px',
    textTransform: 'none',
    color: 'white',
    padding:'10px 15px',
    cursor: 'pointer',
    fontFamily: "'Open Sans', sans-serif",
    fontWeight:400
}

const MyTab = ({badge, ...otherProps}) => (
    <div style={tabStyles.container}>
        <Badge
            badgeContent={badge > 0 ? badge : null}
            color="primary"
            sx={{
                '& .MuiBadge-badge': {
                    backgroundColor: '#1C1D1F',
                    color: '#fff',
                    fontSize: 12,
                    fontFamily: 'Manrope',
                    fontWeight: '500',
                    lineHeight: '20px',
                    minWidth: 20,
                    height: 20,
                    borderRadius: '50%',
                    top: '10px',
                    right: '10px',
                },
            }}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            overlap="rectangular"
        >
            <Tab {...otherProps} sx={{
                "&.Mui-selected": {
                    backgroundColor: "#FFFFFF", color: "#000000",
                },
                "&:not(.Mui-selected)": {
                    backgroundColor: "#FFFFFF", color: "rgba(0, 0, 0, 0.40)",
                },
            }} style={{width: '100%', minWidth: 'auto'}}/>
        </Badge>
    </div>);

const MyTabs = ({handleTabChange, activeTab, unreadMessageCount}) => {

    const [tab, setTab] = React.useState(activeTab || 'tasks');
    const { setCurrentTab, navigate } = useContext(AppContext);

    React.useEffect(() => {
        setTab(activeTab);
    }, [activeTab]);

    const internalHandleTabChange = (event, newValue) => {
        const newTabValue = newValue;
        setTab(newTabValue);
        setCurrentTab(newTabValue);
        handleTabChange(event, newTabValue);   
        
    };

    const getIconStyles = (activeTab, currentTab) => {
        const isActive = activeTab === currentTab;
        return {
            opacity: isActive ? 1 : 0.5, paddingTop: 0
        };
    };

    const getLabelStyles = () => {
        return {
            textTransform: 'none', fontSize: '12px',   fontFamily: "'Open Sans', sans-serif", fontWeight:600
        };
    };

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const calculateUnreadCounts = () => {

        const memberID = localStorage.getItem("memberID")
        const totalUnread = Object.entries(unreadMessageCount)
            .filter(([key]) => key === 'General' || key.includes(`mem_${memberID}`))
            .reduce((sum, [_, value]) => sum + parseInt(value, 10), 0);

        return totalUnread;

    };

    let tabsMap = ['for you','tasks', 'chat', 'account'];


    const handleAddButtonClick = () => {
        navigate('/newTask')
     
    };
    return (
        <Box display="flex" flexDirection="column" alignItems="center" width="100%">

            {tab === 'for you' && (
                <Box
                    margin='10px 0px'
                    width="100%"
                    display="flex"
                    justifyContent="center"
                    gap={2}
                    px={2}
                >
                    {/* <Button variant="outlined" style={buttonStyles}  onClick={null}>
                        Add a note
                    </Button> */}
                <Button variant="outlined" style={buttonStyles} onClick={handleAddButtonClick}  startIcon={<AddTaskIcon />}>
                        Add a task
                    </Button>
                </Box>
            )}
            <Tabs
                value={tab}
                onChange={internalHandleTabChange}
                sx={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    width: '100%',
                    '& .MuiTabs-indicator': {
                        display: 'none'
                    }
                }}
            >
                {tabsMap.map((currentTab) => (<MyTab
                    key={currentTab}
                    icon={ currentTab === 'for you'?
                        (<img
                        alt={currentTab}
                        src={`./app-icons/for.svg`}
                        style={getIconStyles(tab, currentTab)}
                    />): (<img
                        alt={currentTab}
                        src={`./app-icons/${currentTab}.svg`}
                        style={getIconStyles(tab, currentTab)}
                    />)}
                    label={<span style={getLabelStyles()}>
                        {capitalizeFirstLetter(currentTab)}
                    </span>}
                    value={currentTab}
                    wrapped
                    badge={currentTab === 'chat' ? calculateUnreadCounts() : 0}
                />))}
            </Tabs></Box>);
};

export default MyTabs;