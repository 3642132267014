// LogoutDialog.js
import React, { Component } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
} from '@mui/material';

const styles = {
  dialogTitle: {
    fontFamily: "'Open Sans', sans-serif",
    fontWeight: 600,
    fontSize: '18px',
    color: '#1C1D1F',
  },
  dialogContent: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: '14px',
    color: '#555556',
  },
  dialogActions: {
    padding: '0px 16px 16px 16px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  cancelButton: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: '14px',
    color: '#1C1D1F',
    textTransform: 'none',
    flex: '1',
    marginRight: '8px',
    backgroundColor: '#E8E5E0',
  },
  logoutButton: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: '14px',
    color: '#FFFFFF',
    backgroundColor: '#1C1D1F',
    textTransform: 'none',
    flex: '1',
    marginLeft: '8px',
  },
};

class LogoutDialog extends Component {
  render() {
    const { open, onConfirm, onCancel } = this.props;
    return (
      <Dialog open={open} onClose={onCancel}>
        <DialogTitle>
          <Typography sx={styles.dialogTitle}>Confirm Logout</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography sx={styles.dialogContent}>
            Are you sure you want to logout?
          </Typography>
        </DialogContent>
        <DialogActions sx={styles.dialogActions}>
          <Button onClick={onCancel} sx={styles.cancelButton}>
            Cancel
          </Button>
          <Button onClick={onConfirm} sx={styles.logoutButton}>
            Logout
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default LogoutDialog;
