import React from 'react';
import {Button, Box, Popover,Backdrop, CircularProgress} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
//import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import CloseIcon from '@mui/icons-material/Close';
import {AppContext} from './AppContext';

const baseURL = process.env.REACT_APP_API_BASE_URL;

class AlertDialog extends React.Component {
    static contextType = AppContext;
    constructor(props) {
        super(props);
        this.state = {
            open: false, secondDialogOpen: false, swapDialogOpen: false, isDeleting: false,
        };
    }

    handleClickOpen = () => {
        this.setState({open: true});
    };

    handleClose = () => {
        this.setState({open: false});
    };

    handleDeleteClick = () => {
        this.setState({open: false, secondDialogOpen: true});
    };

    handleSecondDialogClose = () => {
        this.setState({secondDialogOpen: false});
    };

    handleSwapClick = (taskIdToBePinned) => {
        this.setState({open: false, swapDialogOpen: true});
        window.scrollTo({
            top: 0, behavior: 'smooth',
        });
        this.props.onChildStateChange(true, taskIdToBePinned);

    };

    handleSwapDialogClose = () => {
        this.setState({swapDialogOpen: false});
        this.props.onChildStateChange(false);

    };

    handlePin = (taskId) => {
        this.props.handlePin(taskId);
        this.setState({open: false});
    };

    handleRemovePin = (taskId) => {
        this.props.handleRemovePin(taskId);
        this.setState({open: false});
    };

    handleHold = (taskId) => {
        this.props.handleHold(taskId);
        this.setState({open: false});
    };

    handleUnhold = (taskId) => {
        this.props.handleUnhold(taskId);
        this.setState({open: false});
    };

    handlePreventPin = () => {
        this.setState({open: false});
    }

    handleDeleteTask = (taskId) => {
        this.setState({isDeleting: true});
        const requestOptions = {
            method: "POST", headers: {
                "Content-type": "application/json; charset=UTF-8",
            }, body: JSON.stringify({
                taskId: taskId,
            }),
        };

        fetch(`${baseURL}/utils/removeClientTask.php`, requestOptions).then((response) => {
            if (response.ok) {
                this.props.onDeleteTask(taskId);
                this.handleSecondDialogClose();
                this.context.clearUnreadMessagesFromServer(taskId)
            } else {
                throw new Error("Failed to remove task");
            }
        })
            .catch((error) => {
                console.error(error);
            }) 
            .finally(() => {
                this.setState({ isDeleting: false });
            });
            
    };


    render() {
        const {secondDialogOpen, isDeleting} = this.state;
        const {itemId, 
            // maxPinnedItems, 
            // currentPinnedCount, 
            // isPinned, 
            isHold, 
            // isInfoNeeded ,  
            open,  
            onClose, 
            anchorEl, 
            isCompleted, 
            index } = this.props;

        const styles = {
            dialogTitle: {
                textAlign: 'left',
                color: '#1C1D1F',
                fontSize: 16,
                fontFamily: 'Manrope',
                fontWeight: '600',
                wordWrap: 'break-word',
            },
            dialogContent: {
                display: "flex",
                alignItems: "center",
                backgroundColor: "#48484C",
                borderRadius: "10px",
                padding: "10px",
                paddingRight: "100px",
                marginBottom: "10px",
                fontFamily: "Manrope",
                cursor: "pointer",
                color: "#fff",
            },
            button: {
                color: "#3F3D3D",
                marginRight: "8px",
                backgroundColor: "#E8E5E0",
                fontSize: "12px",
                textTransform: "none",
            },
            deleteButton: {color: "#ffffff", backgroundColor: "#FF003D", fontSize: "12px", textTransform: "none"},
            dialogContainer: {
                display: "flex",
                alignItems: "center",
                borderRadius: "15px",
                padding: "10px",
                paddingRight: "20px",
                marginBottom: "10px",
                fontFamily: "Manrope",
                cursor: "pointer",
            },
            dialogIcon: {
                color: "#1C1D1F", marginRight: "10px",
            },

        }
        const deleteButtonDisabledStyle = {
            ...styles.deleteButton, backgroundColor: 'grey',
        };


        // const PinContentContainer = ({
        //                                  itemId,
        //                                  isPinned,
        //                                  currentPinnedCount,
        //                                  maxPinnedItems,
        //                                  handlePin,
        //                                  handleRemovePin,
        //                                  handleSwapClick
        //                              }) => {
        //     const handleContentClick = () => {
        //         if (isHold) {
        //             this.handlePreventPin();
        //         } else {
        //             handlePinStatus();
        //         }
        //     };

        //     const handlePinStatus = () => {
        //         if (isPinned) {
        //             handleRemovePin(itemId);
        //         } else if (currentPinnedCount < maxPinnedItems) {
        //             handlePin(itemId);
        //         } else {
        //             handleSwapClick(itemId);
        //         }
        //     };

        //     const pinText = isPinned ? "Move back to Queue" : "Move Task to Top";
        //     const pinIcon = isPinned ? <LowPriorityIcon style={styles.dialogIcon} />:<LowPriorityIcon style={{...styles.dialogIcon, transform: 'rotate(180deg)' }} /> ;

        //     return (<div
        //         style={{...styles.dialogContainer, backgroundColor: !isHold? "#E8E5E0": '#A9A9A9', opacity:!isHold && !isInfoNeeded ? "1": '0.5'}}
        //         onClick={!isHold && !isInfoNeeded? handleContentClick : null} 
        //     >
        //         {pinIcon}
        //         <span style={{color: "#1C1D1F"}}>{pinText}</span>
        //     </div>);
        // };

        // Hold functionality
        // const HoldContentContainer = ({itemId, isHeld, handleHold, handleUnhold, isPinned, handleRemovePin}) => {
        //     const handleContentClick = () => {
        //         if (isPinned && !isHeld) {
        //             handleHold(itemId);
        //             handleRemovePin(itemId);
        //         } else if (!isPinned && !isHeld) {
        //             handleHold(itemId);
        //         } else {
        //             handleUnhold(itemId);
        //         }
        //     };

        //     const containerStyle = {...styles.dialogContainer, backgroundColor: "#E8E5E0"};
        //     const textStyle = {color: "#1C1D1F"};
        //     const holdText = isHeld ? "Take off Hold" : "Put On Hold";

        //     return (<div style={containerStyle} onClick={handleContentClick}>
        //         <PauseCircleOutlineIcon style={styles.dialogIcon}/>
        //         <span style={textStyle}>{holdText}</span>
        //     </div>);
        // };

        const MoveTaskUp = ({index,itemId}) => {
            if (index === 0) {
                return null; 
            }
            return (
                <div style={{...styles.dialogContainer, backgroundColor: "#E8E5E0"}} 
                onClick={() => this.context.moveTaskUp(itemId)}>
                    <LowPriorityIcon style={{...styles.dialogIcon, transform: 'rotate(180deg)' }}/>
                    <span style={{color: "#1C1D1F"}}>Move Up</span>
                </div>
            );
        };

        const MoveTaskDown = ({ itemId }) => {
            const activeItems = this.context.items.filter((item) => !item.isHold);
            const currentIndex = activeItems.findIndex(item => item.id === itemId);
        
            if (currentIndex === activeItems.length - 1) {
                return null; 
            }
        
            return (
                <div 
                    style={{...styles.dialogContainer, backgroundColor: "#E8E5E0"}}
                    onClick={() => this.context.moveTaskDown(itemId)}
                >
                    <LowPriorityIcon style={styles.dialogIcon} />
                    <span style={{color: "#1C1D1F"}}>Move Down</span>
                </div>
            );
        };
        

        const DeleteDialogContent = ({handleDeleteClick}) => (
            <div style={{...styles.dialogContainer, backgroundColor: "#E8E5E0"}} onClick={handleDeleteClick}>
                <DeleteOutlinedIcon style={styles.dialogIcon}/>
                <span style={{color: "#1C1D1F"}}>Delete Task</span>
            </div>);

        return (<>
            <Popover
                open={open}
                onClose={onClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                onClick={(e) => e.stopPropagation()}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                    PaperProps={{ style: { borderRadius: "10px", boxShadow: "none",  zIndex: 1301  },}}
            >
                <Box style={{ padding: '16px' }} onClick={(event) => event.stopPropagation()}>
                    <Box display="flex" alignItems="center" justifyContent="space-between" marginBottom="10px">
                        <span style={styles.dialogTitle}>Options</span>
                        <CloseIcon onClick={onClose} style={{ cursor: 'pointer', color: '#1C1D1F' }} />
                    </Box>
                    {/* Conditionally render options */}
                    {!isCompleted && (
                        <>
                            {/* <PinContentContainer
                                itemId={itemId}
                                isPinned={isPinned}
                                currentPinnedCount={currentPinnedCount}
                                maxPinnedItems={maxPinnedItems}
                                handlePin={this.handlePin}
                                handleRemovePin={this.handleRemovePin}
                                handleSwapClick={this.handleSwapClick}
                            /> */}
                          {!isHold && (
                                    <>
                                        <MoveTaskUp index={index} itemId={itemId} />
                                        <MoveTaskDown index={index} itemId={itemId} />
                                    </>
                                )}
                                {/* Hold functionality */}
                            {/* <HoldContentContainer
                                itemId={itemId}
                                isHeld={isHold}
                                handleHold={this.handleHold}
                                handleUnhold={this.handleUnhold}
                            />                          */}
                        </>
                    )}
                    <DeleteDialogContent handleDeleteClick={this.handleDeleteClick} />
                </Box>
            </Popover>

            <Dialog open={secondDialogOpen} onClose={this.handleSecondDialogClose}
                    aria-labelledby="second-dialog-title" aria-describedby="second-dialog-description"
                    paperprops={{style: {borderRadius: "10px", backgroundColor: "#242426", boxShadow: "none"}}}>
                <DialogTitle id="second-dialog-title" style={styles.dialogTitle}>{"Delete task?"}</DialogTitle>
                <DialogContent onClick={(event) => event.stopPropagation()}>
                    <div style={{display: "flex", alignItems: "center"}}>
                        <Button onClick={this.handleSecondDialogClose} style={styles.button}>Cancel</Button>
                        <Button onClick={() => this.handleDeleteTask(itemId)}
                                style={isDeleting ? deleteButtonDisabledStyle : styles.deleteButton}
                                disabled={isDeleting}>Delete</Button>
                    </div>
                </DialogContent>
            </Dialog>

            <Backdrop
                sx={{ backgroundColor: 'rgba(0, 0, 0, 0.3)', zIndex: 1401, }}
                open={isDeleting}
            >
                <CircularProgress style={{color:'#1C1D1F'}} />
            </Backdrop>

        </>);
    }
}

export default AlertDialog;