import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';

const secureURL = process.env.REACT_APP_SECURE_URL;
const secureKey = process.env.REACT_APP_UPLOAD_API_KEY;

class DeleteDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isDeleting: false,
            error: null,
        };
    }

    deleteFile = async () => {
        const { fileToDelete, category, subCategory, onDeleteSuccess, onClose } = this.props;
        const familyId = localStorage.getItem('familyID');
        const url = `${secureURL}/fileupload/digiLocker.php?familyid=${familyId}&filename=${fileToDelete}&category=${category.name}&subcategory=${subCategory}`;

        this.setState({ isDeleting: true, error: null });

        try {
            const response = await fetch(url, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${secureKey}`,
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json();

            if (result.success) {
                onDeleteSuccess(); // Notify parent to refresh files
                onClose(); // Close the dialog
            } else {
                throw new Error(result.message || 'Failed to delete the file.');
            }
        } catch (error) {
            console.error('Error deleting file:', error);
            this.setState({ error: error.message });
        } finally {
            this.setState({ isDeleting: false });
        }
    };

    render() {
        const { open, onClose } = this.props;
        const { isDeleting, error } = this.state;

        const dialogTitleStyle = {
            fontSize: '16px',
            fontWeight: '600',
            fontFamily: "'Open Sans', sans-serif",
            color: '#1C1D1F',
            textAlign: 'center',
            padding:'16px 24px 5px 24px'
        };

        const dialogContentTextStyle = {
            fontSize: '14px',
            fontWeight: '500',
            fontFamily: "'Open Sans', sans-serif",
            color: '#1C1D1F',
            textAlign: 'center',
            marginTop: '10px',
        };

        const dialogActionsStyle = {
            display: 'flex',
            justifyContent: 'space-around',
            padding: '16px',
        };

        const buttonStyle = {
            width: '120px',
            height: '40px',
            borderRadius: '20px',
            textTransform: 'none',
            fontSize: '16px',
            color: 'white',
            backgroundColor: '#1C1D1F',
            fontFamily: 'Manrope',
        };

        const cancelButtonStyle = {
            ...buttonStyle,
            color: '#1C1D1F',
            backgroundColor: '#fff',
        };

        return (
            <Dialog
                open={open}
                onClose={onClose}
                PaperProps={{
                    style: { backgroundColor: '#E8E5E0', color: '#1C1D1F', borderRadius: '15px' },
                }}
            >
                <DialogTitle style={dialogTitleStyle}>Confirm Delete</DialogTitle>
                <DialogContent sx={{ padding: 0 }}>
                    <Typography style={dialogContentTextStyle}>
                        Are you sure you want to delete this?
                    </Typography>
                    {error && (
                        <Typography style={{ ...dialogContentTextStyle, color: 'red', marginTop: '10px' }}>
                            {error}
                        </Typography>
                    )}
                </DialogContent>
                <DialogActions style={dialogActionsStyle}>
                    <Button onClick={onClose} style={cancelButtonStyle} startIcon={<CloseIcon />}>
                        Cancel
                    </Button>
                    <Button onClick={this.deleteFile} style={buttonStyle} startIcon={<DeleteIcon />} disabled={isDeleting}>
                        {isDeleting ? 'Deleting...' : 'Delete'}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default DeleteDialog;
