import React from 'react';
import { IconButton , Dialog, DialogTitle, List, ListItem, ListItemSecondaryAction, Radio, RadioGroup, ListItemText, Box} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

class CategoryAlertDialog extends React.Component {
    handleRadioChange = (event) => {
        const selectedCategory = event.target.value;
        this.props.onCategoryChange(selectedCategory);
    };
    handleListItemClick = (category) => {
        this.props.onCategoryChange(category);
      };

    render() {
        const { open, onClose, selectedCategory } = this.props;
        const categoriesList = [
            "All Categories",
            "Activities",
            "Admin Finance and Legal",
            "Birthdays and Events",
            "Dining",
            "Education",
            "Gifts and Shopping",
            "Health and Wellbeing",
            "Holidays and Travel",
            "Home and Garden",
            "Family",
            "Pets",
            "Real Estate",
            "Vehicles"
        ];

        return (
       
                <Dialog
                    onClose={onClose}
                    open={open}
                    PaperProps={{
                        style: {
                            borderRadius: "10px",
                            backgroundColor: "#E8E5E0",
                            boxShadow: "none",
                        },
                    }}
                    sx={{
                        "& .MuiDialog-paper": {
                            maxWidth: "300px",
                            width: "100%",
                            height: "70%"
                        },
                    }}
                >
                <DialogTitle style={{ color: "#1C1D1F", padding: '10px 14px', }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <span>Task Categories</span>
                        <IconButton
                            aria-label="close"
                            onClick={onClose}
                            sx={{
                                color: '#1C1D1F',
                                marginLeft: 'auto',
                            }}
                            size="small"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>
                    <List sx={{ pt: 0 }}>
                        <RadioGroup
                            aria-label="task-category"
                            name="task-category"
                            value={selectedCategory}
                            onChange={this.handleRadioChange}
                        >
                            {categoriesList.map((category, index) => (
                                <React.Fragment key={index}>
                                    <ListItem
                                      key={index}
                                      button
                                      onClick={() => this.handleListItemClick(category)}>
                                        <ListItemText
                                            primary={category}
                                            primaryTypographyProps={{
                                                style: {
                                                    color: "#1C1D1F",
                                                },
                                            }}
                                        />
                                        <ListItemSecondaryAction>
                                            <Radio
                                                edge="end"
                                                sx={{
                                                    color: "#1C1D1F",
                                                    "&.Mui-checked": {
                                                        color: "#1C1D1F",
                                                    },
                                                    "&:hover": {
                                                        boxShadow: "0",
                                                    },
                                                    "&:focus": {
                                                        boxShadow: "0",
                                                    },
                                                }}
                                                value={category}
                                            />
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                </React.Fragment>
                            ))}
                        </RadioGroup>
                    </List>
                </Dialog>
        );
    }
}

export default CategoryAlertDialog;
