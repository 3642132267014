import React, { Component } from 'react'
import { Button, Dialog, DialogTitle, DialogContent } from "@mui/material"
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import PhotoSizeSelectActualOutlinedIcon from '@mui/icons-material/PhotoSizeSelectActualOutlined';
import CloseIcon from '@mui/icons-material/Close';

class ChatOptionsDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: this.props.isChatOptionsOpen || false,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.isChatOptionsOpen !== this.props.isChatOptionsOpen) {
            this.setState({ open: this.props.isChatOptionsOpen });
        }
    }

    handleClose = () => {
        this.setState({ open: false });
        this.props.onClose();
    }


    render() {

        const { open } = this.state;

        const styles = {
            dialogTitle: {
                textAlign: 'left',
                color: '#000000',
                fontSize: 16,
                fontFamily: "'Open Sans', sans-serif",
                fontWeight: '500',
                wordWrap: 'break-word',
                padding: 12,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            }, button: {
                display: 'flex',
                color: "#3F3D3D",
                backgroundColor: "#E8E5E0",
                fontFamily: "'Open Sans', sans-serif",
                fontSize: 16,
                textTransform: "none",
                borderRadius: '15px',
                padding:'10px',
                width: '100%',
                margin: '12px 0px',
                justifyContent: 'left'
            }, dialogIcon: {
                color: "#1C1D1F", marginRight: 8,
            },
            closeIcon: {
                color: "#1C1D1F",
            },
        }

        return (<>
            <Dialog
                sx={{ zIndex: 9999999999 }}
                open={open} onClose={this.handleClose}
                aria-labelledby="dialog-title" aria-describedby="dialog-description"
                PaperProps={{
                    style: {
                        display: 'flex',
                        justifyContent: 'center',
                        borderRadius: "6px",
                        backgroundColor: "#FFFFFF",
                        boxShadow: "none",
                        width: "100%",
                        padding: "12px"
                    }
                }}>
                <DialogTitle id="dialog-title" sx={{ padding: '0' }} style={styles.dialogTitle}>
                    Options
                    <CloseIcon style={styles.closeIcon} onClick={this.handleClose} />
                </DialogTitle>
                <DialogContent sx={{ padding: '0' }}>

                    <Button onClick={() => this.props.handleMediaOpen()}
                        style={styles.button}> <PhotoSizeSelectActualOutlinedIcon style={styles.dialogIcon} /> Photo & Video Library</Button>
                    <Button onClick={() => this.props.handleDocumentOpen()}
                        style={styles.button}><InsertDriveFileOutlinedIcon style={styles.dialogIcon} />Document</Button>
                </DialogContent>
            </Dialog>
        </>);
    }
}

export default ChatOptionsDialog;