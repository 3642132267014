export const styles = {
    containerPadding: { padding: '0px 10px' },
    cardStyle: { margin: '10px 0px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', background: '#FFFFFF' },
    cardActionAreaStyle: { display: 'flex', width: '100%', padding: '0px 10px' },
    cardContentFlexGrow: { flexGrow: 1 },
    iconWhiteColor: { color: '#1C1D1F' },
    categoryHeaderStyles: {
        fontSize: '15px',
        color: "#1C1D1F",
        fontWeight: '550',
        fontFamily: "'Open Sans', sans-serif",
        wordWrap: 'break-word',
    },
    descriptionStyles: {
        fontSize: '13px',
        color: "#555556",
        fontWeight: '400',
        fontFamily: "'Open Sans', sans-serif",
        wordWrap: 'break-word',
        marginTop:'2px',
    },
    textFieldStyle: {
        background: '#FFFFFF',
        borderRadius: '8px',
        justifyContent: 'flex-start',
        alignItems: 'center',
        fontFamily: "Manrope",
        fontWeight: "normal",
        flexGrow: 1,
    },
    textFieldMarginZeroStyle: {
        marginBottom: 0,
        padding: 2,
    },
    textFieldSx: {
        input: { color: 'black' },
        '& .MuiOutlinedInput-root': {
            backgroundColor: '#FFFFFF',
            '& fieldset': {
                border: 'none',
            },
            '&.Mui-focused fieldset': {
                border: 'none',
            },
        },
    },
    textFieldInputPropsStyle: {
        color: 'black',
        lineHeight: '2',
        fontSize: '16px',
    },
    iconButtonColorWhiteStyle: { color: 'white' },
    buttonStyle: {
        minWidth: 50,
        minHeight: 50,
        background: '#2F98A3',
        borderRadius: "4px",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 8,
    },
    buttonFullWidthStyle: {
        width: '100%',
        textTransform: 'none',
        fontSize: '18px',
        color: 'white',
    },
    inputFieldGridStyle: {
        boxSizing: 'border-box',
        width: '100%',
        padding: 8,
        justifyContent: 'center',
        alignItems: 'left',
        display: 'flex',
        flexDirection: 'column',
    },
    gridItemStyle: {
        display: 'flex',
        justifyContent: 'start',
        textAlign: 'left',
    },
    gridItemCenterStyle: {
        display: 'flex',
        justifyContent: 'center',
        padding: '18px',
    },
    iconButtonStyle: {
        color: 'white',
        margin: '16px 0px 10px 0px',
        backgroundColor:'#1C1D1F',
        borderRadius:'15px',
        padding:'10px',
    },
    fileNameTextStyle: {
        color: 'white',
        marginLeft: '10px',
        display: 'flex',
        alignItems: 'center',
    },
    closeButtonStyle: {
        color: 'white',
        marginLeft: '5px',
    },
    typographyCategoryHeader: {
        color: "#1C1D1F",
        fontFamily: "'EB Garamond', serif",
        fontSize:'24px'
    },
    typographySubCategoryHeader: {
        color: "#1C1D1F",
        fontFamily: "'Open Sans', sans-serif",
        fontSize:'16px',
        marginTop:'10px'
    },
    menuPaperStyle: {
        backgroundColor: '#FFFFFF',
        color: '#3F3D3D', 
        borderRadius: '6px', 
        padding:'10px'  
    },
    menuItemIconStyle: {
        marginRight: '8px',
        color: '#1C1D1F',

    },
    menuItemStyle: {
        marginTop:'5px',
        backgroundColor: '#E8E5E0',
        borderRadius: '15px',
    },

    optionsTextStyle:{
        textAlign: 'left',
        color: '#1C1D1F',
        fontSize: 16,
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: '600',
        wordWrap: 'break-word',
    },
    flexCenterStyle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    gridItemFlexColumnStyle: {
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'hidden',
        paddingBottom: 'env(safe-area-inset-bottom)',
    },
    topBarGridContainerStyle: {
        alignItems: "center",
        justifyContent: "space-between",
        overflow: "hidden",
    },
    topBarSubGridConatainerStyle:{
        padding: '10px', 
        display: 'flex', 
        justifyContent: 'center' 
    },
    iconButtonBackStyle: {
        color: '#1C1D1F',
    },
    boxStyleMt3: { 
        mt: 3, 
        backgroundColor:'#FFFFFF', 
        padding:'15px' , 
        borderRadius:'8px'
    },
    boxHeaderStyles:{
        color: '#1C1D1F', 
        marginBottom: '10px', 
        fontWeight:'600', 
        fontFamily: "'Open Sans', sans-serif",
        fontSize:'14px' ,
    },
    boxStyleFileItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '10px',
        cursor: 'pointer',
        padding:'5px',
        backgroundColor:'#F5F3F0' ,
        borderRadius:'15px'
    },
    boxStyleFileItemTitle:{
        color: '#1C1D1F', 
        fontSize: '14px', 
        marginLeft: '8px' ,
        fontFamily: "'Open Sans', sans-serif",
    },
    circularProgressStyle: { color: '#1C1D1F' },
    gridInputFieldStyle: {
        paddingBottom: `env(safe-area-inset-bottom, 15px)`,
    },
};

export const buttonSaveStyle = (isSubmitting) => ({
  ...styles.buttonStyle,
  ...styles.buttonFullWidthStyle,
  backgroundColor: isSubmitting ? '#B1B1B1' : '#1C1D1F',
});