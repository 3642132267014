import React from 'react';
import {
    Typography, Grid, TextField, Button, IconButton, Box,Card, CardActionArea, CardContent, Container,CircularProgress, Menu , MenuItem , InputAdornment
} from "@mui/material";
import { AppContext } from '../AppContext';
import DeleteDialog from './Dialogs/DeleteDialog';
import EditDialog from './Dialogs/EditDialog';
import FileViewDialog from './Dialogs/FileViewDialog';
import CloseIcon from '@mui/icons-material/Close';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DescriptionIcon from '@mui/icons-material/Description'; // Default file icon
import ImageIcon from '@mui/icons-material/Image';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import '../../styles/style.css'

import { categories, subCategoryMap, dynamicFields} from './LockerConstants';
import { styles, buttonSaveStyle} from './LockerStyles'
import { ChevronLeft } from '@mui/icons-material';

const secureURL = process.env.REACT_APP_SECURE_URL;
const secureKey = process.env.REACT_APP_UPLOAD_API_KEY;


class Locker extends React.Component {
    static contextType = AppContext;
    constructor(props) {
        super(props);
        this.state = {
            stage: 'category', // 'category', 'subCategory', 'form'
            taskText: '',
            file: null,
            topBarHeight: 0,
            inputFieldHeight: 0,
            isSubmitting: false,
            files: [],
            category: '',
            subCategory: '',
            hasFiles: false,
            dynamicFields: {},
            isFetchingFiles: false,
            isDeleteDialogOpen: false,
            fileToDelete: null,
            isEditDialogOpen: false,
            fileToEdit: null,
            editDynamicFields: {},
            isFileDialogOpen: false,
            fileName: '',
            menuAnchorEl: null,
            selectedFile: null,
            showPasswordFields: {},
        };
        this.topBar = React.createRef();
    }
    componentDidMount() {
        this.updateHeights();
        window.addEventListener('resize', this.updateHeights);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateHeights);
    }

    handleCategorySelection = (category) => {
        this.setState({ category: category, stage: 'subCategory' });
    }

    handleSubCategorySelection = async (subCategory) => {
        this.setState({ subCategory, dynamicFields: {} }, async () => {
            await this.fetchFiles();
            this.setState({ stage: 'form' });
        });
    }
    

    updateHeights = () => {
        const topBarHeight = this.topBar.current ? this.topBar.current.clientHeight : 0;
        this.setState({
            topBarHeight,
        });
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    }

    handleFileChange = (event) => {
        this.setState({ file: event.target.files[0] });
    }

    handleTogglePasswordVisibility = (fieldName) => {
        this.setState(prevState => ({
            showPasswordFields: {
                ...prevState.showPasswordFields,
                [fieldName]: !prevState.showPasswordFields[fieldName],
            }
        }));
    };
    

    handleMenuOpen = (event, file) => {
        this.setState({
            menuAnchorEl: event.currentTarget,
            selectedFile: file,
        });
    };

    handleMenuClose = () => {
        this.setState({
            menuAnchorEl: null,
            selectedFile: null,
        });
    };

    handleView = () => {
        const { selectedFile } = this.state;
        if (selectedFile) {
            this.openFile(selectedFile.originalFileName || selectedFile);
        }
        this.handleMenuClose();
    };

    handleDownload = () => {
        const { selectedFile } = this.state;
        if (selectedFile) {
            this.openFile(selectedFile.originalFileName || selectedFile)
        }
        this.handleMenuClose();
    };

    handleEdit = () => {
        const { selectedFile } = this.state;
        if (selectedFile && selectedFile.originalFileName) {
            this.setState({
                isEditDialogOpen: true,
                fileToEdit: selectedFile.originalFileName,
            });
        }
        this.handleMenuClose();
    };

    handleDelete = () => {
        const { selectedFile } = this.state;
        if (selectedFile) {
            this.setState({ isDeleteDialogOpen: true, fileToDelete: selectedFile.originalFileName || selectedFile });
        }
        this.handleMenuClose();
    };
    

    removeFile = () => {
        this.setState({ file: null });
    }

    openFile = (fileName) => {
        this.setState({
            isFileDialogOpen: true,
            fileName,
        });
    }
    

    getFileIcon = (fileName) => {
        const ext = fileName.split('.').pop().toLowerCase();
        switch (ext) {
            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'gif':
                return <ImageIcon style={{ color: 'black' }} />;
            case 'pdf':
            case 'doc':
            case 'docx':
                return <InsertDriveFileIcon style={{ color: 'black' }} />;
            default:
                return <DescriptionIcon style={{ color: 'black' }} />;
        }
    };

    handleFieldChange = (fieldName, value) => {
        this.setState(prevState => ({
            dynamicFields: {
                ...prevState.dynamicFields,
                [fieldName]: value
            }
        }));
    };

    handleEditFieldChange = (fieldName, value) => {
        this.setState(prevState => ({
            editDynamicFields: {
                ...prevState.editDynamicFields,
                [fieldName]: value
            }
        }));
    };
    
    

    handleSubmit = async () => {
        this.setState({ isSubmitting: true });
        const familyId = localStorage.getItem('familyID');
        const formData = new FormData();
        formData.append('familyid', familyId);
        formData.append('category', this.state.category.name);
        formData.append('subcategory', this.state.subCategory);
        if (this.state.file) {
            formData.append('file', this.state.file);
        }

        let fields = {
            ...this.state.dynamicFields,
            category: this.state.category.name,
            subcategory: this.state.subCategory,
        };
    

        if (this.state.taskText && this.state.taskText.trim() !== '') {
            fields.taskText = this.state.taskText;
        }
    

        const fieldsJson = JSON.stringify(fields);
        formData.append('textfield', fieldsJson);

        try {
            const response = await fetch(`${secureURL}/fileupload/digiLocker.php`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${secureKey}`,
                },
                body: formData,
            });
            const result = await response.json();
            if (result.success) {
                this.setState({ taskText: '', file: null });
                this.fetchFiles();
            } else {
                console.error(result.message);
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        } finally {
            this.setState({ isSubmitting: false });
        }
    }

    async fetchFileDetails(file) {
        const familyId = localStorage.getItem('familyID');
        const { category, subCategory } = this.state;

        if (file.endsWith('.json')) {
            const url = `${secureURL}/fileupload/digiLocker.php?filename=${file}&familyid=${familyId}&category=${category.name}&subcategory=${subCategory}`;
            try {
                const fileResponse = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${secureKey}`,
                    },
                });
                const textContent = await fileResponse.text();
                let jsonData;
                try {
                    jsonData = JSON.parse(textContent);
                } catch (e) {
                    console.error('Error parsing JSON:', e);
                    jsonData = {};
                }

                jsonData.textField = JSON.parse(jsonData.textField);
                const fileCategory = jsonData.textField.subcategory || '';
                const name = jsonData.textField.name || 'Details';
                const displayName = `${fileCategory} - ${name}`;
                return { originalFileName: file, displayName };
            } catch (error) {
                console.error('Error fetching JSON file:', error);
                return { originalFileName: file, displayName: file };
            }
        } else {
            return { originalFileName: file, displayName: file };
        }
    }

    fetchFiles = async () => {
        this.setState({ isFetchingFiles: true });
        const familyId = localStorage.getItem('familyID');
        const { category, subCategory } = this.state;
        try {
            const response = await fetch(`${secureURL}/fileupload/digiLocker.php?familyid=${familyId}&category=${category.name}&subcategory=${subCategory}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${secureKey}`,
                },
            });
            const result = await response.json();
            if (result.success) {
                const files = result.files;

                // Separate JSON files and attachments
                const jsonFiles = files.filter(file => file.endsWith('.json'));
                const attachmentFiles = files.filter(file => !file.endsWith('.json'));

                // Process JSON files to get display names
                const jsonFilesWithDetails = await Promise.all(jsonFiles.map(file => this.fetchFileDetails(file)));

                // Update state with separated files
                this.setState({ jsonFiles: jsonFilesWithDetails, attachmentFiles });
                if (jsonFilesWithDetails.length > 0 || attachmentFiles.length > 0) {
                    this.setState({ hasFiles: true });
                }
            } else {
                alert(result.message);
            }
        } catch (error) {
            console.error('Error fetching files:', error);
        } finally {
            this.setState({ isFetchingFiles: false });
        }
    }
    

    handleBack = () => {
        const { stage } = this.state;
        if (stage === 'form') {
            this.setState({ stage: 'subCategory', hasFiles:false});
        } else if (stage === 'subCategory') {
            this.setState({ stage: 'category' });
        } else if (stage === 'category') {
            this.context.navigate('/')
        }
    }

    handleAddNewDetails = ()=> {
        this.setState({
            hasFiles:false
        })
    }

    getDynamicFields = (subCategory) => {
        return dynamicFields[subCategory] || [];
    };
      

    renderCategorySelect() {
        return (
            <div style={styles.containerPadding}>
                {categories.map((category, index) => (
                    <Card key={index} elevation={0} onClick={() => this.handleCategorySelection(category)} style={styles.cardStyle}>
                        <CardActionArea style={styles.cardActionAreaStyle}>
                            <CardContent style={styles.cardContentFlexGrow}>
                                <Typography style={styles.categoryHeaderStyles}>{category.name}</Typography>
                                <Typography style={styles.descriptionStyles}>{category.description}</Typography>
                            </CardContent>
                            <KeyboardArrowRightIcon style={styles.iconWhiteColor} />
                        </CardActionArea>
                    </Card>
                ))}
            </div>
        )
    }

    renderSubCategorySelect = () => {

        const subCategories = subCategoryMap[this.state.category.name];

        return (subCategories ? (
            <div style={styles.containerPadding}>
            {subCategories.map((subCategory, index) => (
                <Card key={index} elevation={0} onClick={() => this.handleSubCategorySelection(subCategory)} style={styles.cardStyle}>
                    <CardActionArea style={styles.cardActionAreaStyle}>
                        <CardContent style={styles.cardContentFlexGrow}>
                            <Typography style={styles.categoryHeaderStyles}>{subCategory}</Typography>
                        </CardContent>
                        <KeyboardArrowRightIcon style={styles.iconWhiteColor} />
                    </CardActionArea>
                </Card>
            ))}</div>)  : null)
    };

    renderForm() {
        const { taskText, isSubmitting, subCategory , dynamicFields, showPasswordFields } = this.state;
        const inputFields = this.getDynamicFields(subCategory);

        return (
            <>

                <Grid item xs={12} style={styles.inputFieldGridStyle}>

                    <Typography style={styles.typographyCategoryHeader} variant="h6" gutterBottom>
                        {this.state.category.name}
                    </Typography>

                    <Typography style={styles.typographySubCategoryHeader} gutterBottom>
                        {this.state.subCategory}
                    </Typography>

                    {inputFields.length === 0 ? (
                        <TextField
                            style={styles.textFieldStyle}
                            size='small'
                            placeholder={`Send to the assistant`}
                            value={taskText}
                            onChange={this.handleChange}
                            name="taskText"
                            fullWidth
                            multiline
                            sx={styles.textFieldSx}
                            inputProps={{
                                style: styles.textFieldInputPropsStyle
                            }}
                        />
                    ) : (

                        inputFields.map((field, index) => {
                            const isPassword = field.type === 'password';
                            const isVisible = showPasswordFields[field.name] || false;
                            return (
                                <TextField
                                    key={index}
                                    name={field.name}
                                    placeholder={field.label}
                                    type={isPassword ? (isVisible ? 'text' : 'password') : field.type}
                                    value={dynamicFields[field.name] || ''}
                                    onChange={(e) => this.handleFieldChange(field.name, e.target.value)}
                                    fullWidth
                                    margin="normal"
                                    style={{ ...styles.textFieldStyle, ...styles.textFieldMarginZeroStyle }}
                                    size='small'
                                    sx={styles.textFieldSx}
                                    inputProps={{
                                        style: styles.textFieldInputPropsStyle,
                                    }}
                                    InputProps={{
                                        endAdornment: isPassword && (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => this.handleTogglePasswordVisibility(field.name)}
                                                    edge="end"
                                                    style={styles.iconButtonColorWhiteStyle}
                                                >
                                                    {isVisible ? <VisibilityOffIcon fontSize="small" /> : <VisibilityIcon fontSize="small" />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            );
                        })
                    )}

                    <Grid item xs={12} style={styles.gridItemStyle}>
                        <input
                            accept="image/*,.pdf,.doc,.docx,.svg,.png"
                            style={{ display: 'none' }}
                            id="raised-button-file"
                            multiple
                            type="file"
                            onChange={this.handleFileChange}
                        />
                        <label htmlFor="raised-button-file">
                            <IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="span"
                                style={styles.iconButtonStyle}
                            >
                                <AttachFileIcon fontSize='small' />
                                <Typography style={{ marginLeft: '5px',  fontSize:'14px' }}>Attach File</Typography>
                            </IconButton>
                        </label>
                        {this.state.file && (<Typography
                            style={styles.fileNameTextStyle}>
                            {this.state.file.name}
                            <IconButton
                                onClick={this.removeFile}
                                size="small"
                                style={styles.closeButtonStyle}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Typography>)}
                    </Grid>
                </Grid>
                <Box>
                    <Grid container style={styles.gridInputFieldStyle}>
                        <Grid item xs={12} style={styles.gridItemCenterStyle}>
                            <Button
                                style={buttonSaveStyle(isSubmitting)}
                                onClick={this.handleSubmit}
                                disabled={isSubmitting}
                            >Save</Button>
                        </Grid>
                    </Grid>
                </Box>
            </>
        );
    }

    renderFilesList() {
        const buttonStyle = {
            background: '#1C1D1F',
            borderRadius: '4px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding:'10px',
            textTransform: 'none',
            marginTop: '15px',
            color:'white',
            fontFamily: "'Open Sans', sans-serif",
        
        };
        const { jsonFiles = [], attachmentFiles = [], menuAnchorEl, selectedFile } = this.state;

        const isJsonFile = selectedFile && selectedFile.originalFileName && selectedFile.originalFileName.endsWith('.json');


        return (
            <Container>
                <Box>
                    <Typography style={styles.typographyCategoryHeader}>
                        {this.state.category.name}
                    </Typography>

                    <Typography style={styles.typographySubCategoryHeader} >
                        {this.state.subCategory}
                    </Typography>

                    <Button style={buttonStyle} fullWidth
                        onClick={() => this.handleAddNewDetails()}>
                        Add New Details
                    </Button>

                    {/* Section for JSON files */}
                    {jsonFiles.length > 0 && (
                        <Box sx={styles.boxStyleMt3}>
                            <Typography variant="body1" style={styles.boxHeaderStyles}>
                                Saved Details
                            </Typography>
                            {jsonFiles.map((file, index) => (
                                <Box
                                    key={index}
                                    sx={styles.boxStyleFileItem}
                                >
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}
                                        onClick={() => this.openFile(file.originalFileName)}
                                    >
                                        {this.getFileIcon(file.originalFileName)}
                                        <Typography style={styles.boxStyleFileItemTitle}>
                                            {file.displayName}
                                        </Typography>
                                    </Box>
                                    <IconButton
                                        onClick={(e) => this.handleMenuOpen(e, file)}
                                        style={styles.iconWhiteColor}
                                    >
                                        <MoreVertIcon />
                                    </IconButton>
                                </Box>
                            ))}
                        </Box>
                    )}

                    {/* Section for attachments */}
                    {attachmentFiles.length > 0 && (
                        <Box sx={styles.boxStyleMt3}>
                            <Typography variant="body1" style={styles.boxHeaderStyles}>
                                Saved Attachments
                            </Typography>
                            {attachmentFiles.map((file, index) => (
                                <Box
                                    key={index}
                                    sx={styles.boxStyleFileItem}
                                >
                                    <Box sx={{ display: 'flex', alignItems: 'center'}}
                                        onClick={() => this.openFile(file)}
                                    >
                                        {this.getFileIcon(file)}
                                        <Typography style={styles.boxStyleFileItemTitle}>
                                            Attachment {index + 1}
                                        </Typography>
                                    </Box>
                                    <IconButton
                                        onClick={(e) => this.handleMenuOpen(e, file)}
                                        style={styles.iconWhiteColor}
                                    >
                                        <MoreVertIcon />
                                    </IconButton>
                                </Box>
                            ))}
                        </Box>
                    )}
                </Box>

                {/* Menu Component */}
                <Menu
                    anchorEl={menuAnchorEl}
                    open={Boolean(menuAnchorEl)}
                    onClose={this.handleMenuClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    PaperProps={{
                        style: styles.menuPaperStyle,
                    }}
                >
                     <Box display="flex" alignItems="center" justifyContent="space-between" marginBottom="10px">
                            <span style={styles.optionsTextStyle}>Options</span>
                            <CloseIcon onClick={this.handleMenuClose}  style={{ cursor: 'pointer', color: '#1C1D1F' }} />
                        </Box>
                    <MenuItem onClick={this.handleView} style={styles.menuItemStyle}>  <VisibilityIcon style={styles.menuItemIconStyle} />  View</MenuItem>
                    <MenuItem onClick={this.handleDownload} style={styles.menuItemStyle}> <FileDownloadIcon style={styles.menuItemIconStyle} />Download</MenuItem>

                    {isJsonFile && (
                        <MenuItem onClick={this.handleEdit} style={styles.menuItemStyle}>
                            <EditIcon style={styles.menuItemIconStyle} /> Edit
                        </MenuItem>
                    )}

                    <MenuItem onClick={this.handleDelete} style={styles.menuItemStyle}> <DeleteIcon style={styles.menuItemIconStyle} />Delete</MenuItem>
                </Menu>
            </Container>
        );
    }


    
    
    render() {
        const {  stage , isFetchingFiles  } = this.state;

        let title;
        if (stage === 'subCategory') {
            title = this.state.category.name;
        } else if (stage === 'form') {
            title = '';
        } else {
            title = 'Digital Locker';
        }


        return (
            <div style={styles.gridItemFlexColumnStyle}>
                <Box>
                    <Grid container ref={this.topBar} style={styles.topBarGridContainerStyle}>
                        <Grid item xs={1} sm={1} style={styles.topBarSubGridConatainerStyle}>
                            <IconButton onClick={this.handleBack} style={styles.iconButtonBackStyle}>
                                {!isFetchingFiles && <ChevronLeft />}
                            </IconButton>
                        </Grid>
                        <Grid item xs={10} sm={10} style={{padding: 12, display: 'flex', justifyContent: 'left'}}>
                            <Typography variant="h7"  component="div"
                                style={{
                                    textAlign: 'left', fontFamily: 'Manrope', fontWeight: '600', color: 'black'
                                }}>
                                {!isFetchingFiles && title}
                            </Typography>
                    </Grid>
                        <Grid item xs={1} sm={1}></Grid>
                    </Grid>
                </Box>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    overflowY: 'auto',
                    height: `calc(100vh - ${this.state.topBarHeight}px - env(safe-area-inset-bottom))`,
                    paddingBottom: 'env(safe-area-inset-bottom)',
                    paddingTop: 'env(safe-area-inset-top)',
                }}>
                    <DeleteDialog
                        open={this.state.isDeleteDialogOpen}
                        fileToDelete={this.state.fileToDelete}
                        category={this.state.category}
                        subCategory={this.state.subCategory}
                        onDeleteSuccess={this.fetchFiles}
                        onClose={() => this.setState({ isDeleteDialogOpen: false, fileToDelete: null })}
                    />

                    <EditDialog
                        isOpen={this.state.isEditDialogOpen}
                        onClose={() => this.setState({ isEditDialogOpen: false, fileToEdit: null })}
                        onSaveSuccess={this.fetchFiles}
                        fileToEdit={this.state.fileToEdit}
                        category={this.state.category}
                        subCategory={this.state.subCategory}
                        getDynamicFields={this.getDynamicFields}
                    />


                    <FileViewDialog
                        isOpen={this.state.isFileDialogOpen}
                        onClose={() => {
                            this.setState({
                                isFileDialogOpen: false,
                                fileName: '',
                            });
                        }}
                        fileName={this.state.fileName}
                        familyId={localStorage.getItem('familyID')}
                        category={this.state.category.name}
                        subCategory={this.state.subCategory}
                    />

                    {isFetchingFiles ? (
                        <div style={styles.flexCenterStyle}>
                            <CircularProgress style={styles.circularProgressStyle} />
                        </div>
                    ) : (
                        <Box sx={{ p: 1 }}>
                            {stage === 'category' && this.renderCategorySelect()}
                            {stage === 'subCategory' && this.renderSubCategorySelect()}
                            {stage === 'form' && !this.state.hasFiles && this.renderForm()}
                            {stage === 'form' && this.state.hasFiles && this.renderFilesList()}
                        </Box>
                    )}
               
                   
                </div>
             
            </div>
        )
    }
}
export default Locker;