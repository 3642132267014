import React, { Component } from 'react';
import { AppContext } from './AppContext';
import { Grid, Card, Typography, Badge, Box, Avatar } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import PeopleIcon from '@mui/icons-material/People';
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import LogoutDialog from './LogoutDialog'; 

const styles = {
  gridContainer: {
    padding: '16px',
  },
  accountTypography: {
    fontFamily: "'Open Sans', sans-serif",
    fontWeight: 600,
    fontSize: '14px',
  },
  card: {
    cursor: 'pointer',
    backgroundColor: 'white',
    color: 'inherit',
    borderRadius: '8px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  avatar: {
    width: 50,
    height: 50,
    backgroundColor: '#898787',
  },
  avatarLarge: {
    width: 62,
    height: 62,
    marginRight:'8px'
  },
  headerTypography: {
    fontFamily: "'Open Sans', sans-serif",
    fontWeight: 600,
    fontSize: '14px',
  },
  subheaderTypography: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: '13px',
    color: '#555556',
  },
  headerImage: {
    width: '60px',
    height: '60px',
    borderRadius: '15px', 
    objectFit: 'cover',
    marginLeft: '16px',
  },
  icon: {
    marginRight: '16px',
    width: 40,
    height: 40,
    color: '#1C1D1F',
    backgroundColor: '#F5F5F5',
    borderRadius: '25px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  boxWithFlexAlignCenterPadding: {
    display: 'flex',
    alignItems: 'center',
    padding: '12px 16px',
  },
  flexGrow: {
    flexGrow: 1,
  },
  flexAlignItemsCenterMarginLeftAuto: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
  },
  arrowIconWithMarginLeft: {
    marginLeft: '8px',
  },
  arrowIconMarginLeftAuto: {
    marginLeft: 'auto',
  },
  badgeSx: {
    '& .MuiBadge-badge': {
      backgroundColor: 'black',
      color: 'white',
    },
  },
};

class MyAccountPage extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      familyID: localStorage.getItem('familyID') || null,
      isLogoutDialogOpen: false, 
    };
  }

  refer = () => {
    this.context.navigate('/refer');
  };

  locker = () => {
    this.context.navigate('/locker');
  };

  notifications = () => {
    this.context.navigate('/notifications');
  };

  // Open the logout dialog
  logout = () => {
    this.setState({ isLogoutDialogOpen: true });
  };

  // Confirm logout
  handleLogoutConfirm = () => {
    this.setState({ isLogoutDialogOpen: false });
    this.props.onLogout();
  };

  // Cancel logout
  handleLogoutCancel = () => {
    this.setState({ isLogoutDialogOpen: false });
  };

  calculateNotificationCount = () => {
    const unreadNotifications = this.context.notificationData.filter(
      (notification) => !notification.timestamp_read
    );
    return unreadNotifications.length;
  };

  getInitials = (name) => {
    if (!name) return '';
    const names = name.trim().split(' ');
    const initials = names.map((n) => n.charAt(0).toUpperCase());
    return initials.slice(0, 2).join('');
  };

  render() {
    const unreadNotificationCount = this.calculateNotificationCount();
    const memberID = localStorage.getItem('memberID');

    const { members } = this.context;

    const member = members.find((m) => m.ID === memberID) || {};
    const memberName = member.Name || 'Member';

    const initials = this.getInitials(memberName);

    const profilePic = null;
    return (
      <Grid container spacing={2} sx={styles.gridContainer}>
        <Grid item xs={12}>
          <Typography align="left" sx={styles.accountTypography} gutterBottom>
            Account
          </Typography>
        </Grid>

        <LogoutDialog
          open={this.state.isLogoutDialogOpen}
          onConfirm={this.handleLogoutConfirm}
          onCancel={this.handleLogoutCancel}
        />

        {/* My Information Card */}
        <Grid item xs={12} sm={6} marginBottom={2}>
          <Card sx={styles.card} onClick={null} elevation={0}>
            <Box sx={styles.boxWithFlexAlignCenterPadding}>
            {profilePic ? (
              <Avatar
                src={profilePic}
                alt="Profile"
                style={styles.avatarLarge}
                variant="rounded"
              />
            ) : (
              <div style={{ padding: '6px', background: '#555556', borderRadius: '10px', marginRight:'8px' }}>
                <Avatar
                  alt="Profile"
                  style={styles.avatar}
                >
                  {initials}
                </Avatar>
              </div>
            )}

              {/* Text Section */}
              <Box>
                <Typography sx={styles.headerTypography}>{memberName}</Typography>
                <Typography sx={styles.subheaderTypography}>
                  My Information
                </Typography>
              </Box>
            </Box>
          </Card>
        </Grid>


        {/* Refer a Friend Card */}
        <Grid item xs={12} sm={6}>
          <Card onClick={this.refer} sx={styles.card} elevation={0}>
            <Box sx={styles.boxWithFlexAlignCenterPadding}>
              <Box sx={styles.icon}>
                <PeopleIcon />
              </Box>
              <Box>
                <Typography sx={styles.headerTypography}>
                  Refer a Friend
                </Typography>
                <Typography sx={styles.subheaderTypography}>
                  Invite friends to join us.
                </Typography>
              </Box>
              <ArrowForwardIosIcon
                sx={styles.arrowIconMarginLeftAuto}
                fontSize="small"
              />
            </Box>
          </Card>
        </Grid>

        {/* Notifications Card */}
        <Grid item xs={12} sm={6}>
          <Card onClick={this.notifications} sx={styles.card} elevation={0}>
            <Box sx={styles.boxWithFlexAlignCenterPadding}>
              <Box sx={styles.icon}>
                <NotificationsActiveIcon />
              </Box>
              <Box sx={styles.flexGrow}>
                <Typography sx={styles.headerTypography}>
                  General Notifications
                </Typography>
                <Typography sx={styles.subheaderTypography}>
                  View your notifications.
                </Typography>
              </Box>
              <Box sx={styles.flexAlignItemsCenterMarginLeftAuto}>
                {unreadNotificationCount > 0 && (
                  <Badge
                    badgeContent={unreadNotificationCount}
                    sx={styles.badgeSx}
                  />
                )}
                <ArrowForwardIosIcon
                  sx={styles.arrowIconWithMarginLeft}
                  fontSize="small"
                />
              </Box>
            </Box>
          </Card>
        </Grid>


        {/* Locker Card : to be done later */}
        {/* <Grid item xs={12} sm={6}>
          <Card onClick={this.locker} sx={styles.card} elevation={0}>
            <Box sx={styles.boxWithFlexAlignCenterPadding}>
              <Box sx={styles.icon}>
                <LockOutlinedIcon />
              </Box>
              <Box>
                <Typography sx={styles.headerTypography}>Locker</Typography>
                <Typography sx={styles.subheaderTypography}>
                  Access your locker.
                </Typography>
              </Box>
              <ArrowForwardIosIcon
                sx={styles.arrowIconMarginLeftAuto}
                fontSize="small"
              />
            </Box>
          </Card>
        </Grid> */}

        {/* Logout Card */}
        <Grid item xs={12} sm={6}>
          <Card onClick={this.logout} sx={styles.card} elevation={0}>
            <Box sx={styles.boxWithFlexAlignCenterPadding}>
              <Box sx={styles.icon}>
                <LogoutIcon />
              </Box>
              <Box>
                <Typography sx={styles.headerTypography}>Logout</Typography>
                <Typography sx={styles.subheaderTypography}>
                  Sign out of your account.
                </Typography>
              </Box>
              <ArrowForwardIosIcon
                sx={styles.arrowIconMarginLeftAuto}
                fontSize="small"
              />
            </Box>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

export default MyAccountPage;
