
// Categories
export const categories = [
    { name: "My ID", description: "Securely save your ID so we can make bookings for you" },
    { name: "Children's ID", description: "Securely save your children's ID" },
    { name: "Healthcare", description: "Provide doctor, dentist and other providers so we can make bookings for you" },
    { name: "Travel", description: "Please tell us about your preferred airlines and travel options" },
    { name: "Home Accounts", description: "Upload your documentation so we can remind when things run out or need to be renewed" },
    { name: "Memberships", description: "Your clubs, gym etc" },
    { name: "Employee Benefits", description: "Your logins - we'll check what you have available and help you use it up" },
    { name: "Cars", description: "Add your car details and we will help with MOT, insurance etc." },
    { name: "Mobile Phones", description: "Add your mobile phone provider details and we'll keep an eye on deals" },
    { name: "Personal Shopping", description: "Please provide your membership cards and we can add points to them" },
    { name: "Groceries & Meal Deliveries", description: "Please provide your favourite supermarkets and any grocery deliveries you have" },
    { name: "Other", description: "Any other details you might want to share with your assistant" },
  ];
  
  // Subcategory Map
  export const subCategoryMap = {
    "My ID": ["Passport", "Driving Licence", "NHS Number", "Others"],
    "Children's ID": ["Passport", "Others"],
    "Healthcare": ["NHS Number", "Vaccinations/Immunisation", "Others"],
    "Home Accounts": ["Insurance", "Gas", "Electric", "Water", "Broadband", "TV Cable", "Security", "Others"],
    "Memberships": ["Gym Memberships", "Club Memberships", "Others"],
    "Employee Benefits": ["Health Insurance", "Retirement Plans", "Others"],
    "Cars": ["Car Registrations", "Car Insurance", "Congestion Charge / Ulez Login", "Others"],
    "Mobile Phones": ["Plan Offers", "Upgrade Options", "Others"],
    "Travel": ["Taxi", "Airlines", "Trains", "Railcards", "Others"],
    "Personal Shopping": ["Nectar Card", "Clubcard", "Boots Advantage Cards", "Other Rewards Card", "Others"],
    "Other": ["Others"],
  };
  
  // Common Field Groups
  const utilityFields = [
    { name: 'name', label: 'Name Of Company', type: 'text' },
    { name: 'accountNumber', label: 'Account Number', type: 'text' },
    { name: 'notes', label: 'Notes', type: 'text' },
  ];
  
  const insuranceFields = [
    { name: 'name', label: 'Name Of Company', type: 'text' },
    { name: 'policyNumber', label: 'Policy Number', type: 'text' },
    { name: 'notes', label: 'Notes', type: 'text' },
  ];
  
  const membershipFields = [
    { name: 'name', label: 'Name of Membership/Club', type: 'text' },
    { name: 'MemberName', label: 'Name of Membership Holder', type: 'text' },
    { name: 'membershipId', label: 'Email', type: 'text' },
    { name: 'membershipPassword', label: 'Password', type: 'password' },
  ];
  
  const planFields = [
    { name: 'name', label: 'Name', type: 'text' },
    { name: 'planId', label: 'Plan ID', type: 'text' },
  ];
  
  const cardFields = [
    { name: 'name', label: 'Name', type: 'text' },
    { name: 'cardNumber', label: 'Card Number', type: 'text' },
  ];
  
  // Dynamic Field Mappings
  export const dynamicFields = {
    "Passport": [
      { name: 'name', label: 'Name of Passport Holder', type: 'text' },
    ],
    "Driving Licence": [
      { name: 'name', label: 'Name of Licence Holder', type: 'text' },
    ],
    "Others": [
      { name: 'name', label: 'Name', type: 'text' },
      { name: 'description', label: 'Description', type: 'text' },
    ],
    "NHS Number": [
      { name: 'name', label: 'Name', type: 'text' },
      { name: 'nhsNumber', label: 'Number', type: 'text' },
    ],
    "Vaccinations/Immunisation": [
      { name: 'name', label: 'Name', type: 'text' },
    ],
    "Insurance": insuranceFields,
    "Gas": utilityFields,
    "Electric": utilityFields,
    "Water": utilityFields,
    "Broadband": utilityFields,
    "TV Cable": utilityFields,
    "Security": [
      { name: 'name', label: 'Name', type: 'text' },
      { name: 'serviceProvider', label: 'Service Provider', type: 'text' },
    ],
    "Gym Memberships": membershipFields,
    "Club Memberships": membershipFields,
    "Health Insurance": insuranceFields,
    "Retirement Plans": planFields,
    "Car Registrations": [
      { name: 'name', label: 'Name of Vehicle or Owner Of the Vehicle', type: 'text' },
      { name: 'registrationNumber', label: 'Registration Number', type: 'text' },
    ],
    "Car Insurance": insuranceFields,
    "Congestion Charge / Ulez Login": [
      { name: 'name', label: 'Name', type: 'text' },
      { name: 'loginDetails', label: 'Login Details', type: 'text' },
    ],
    "Plan Offers": planFields,
    "Upgrade Options": [
      { name: 'name', label: 'Name', type: 'text' },
      { name: 'upgradeDetails', label: 'Upgrade Details', type: 'text' },
    ],
    "Taxi": [
      { name: 'name', label: 'Service Provider', type: 'text' },
    ],
    "Airlines": [
      { name: 'name', label: 'Name Of Airline', type: 'text' },
      { name: 'AirlinesUserId', label: 'User ID', type: 'text' },
    ],
    "Trains": [
      { name: 'name', label: 'Name', type: 'text' },
      { name: 'railcardNumber', label: 'Railcard Number', type: 'text' },
    ],
    "Railcards": cardFields,
    "Nectar Card": cardFields,
    "Clubcard": cardFields,
    "Boots Advantage Cards": cardFields,
    "Other Rewards Card": cardFields,
  };
  

  export const styles = {
    containerPadding: { padding: '0px 10px' },
    cardStyle: { margin: '10px 0px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', background: '#202020' },
    cardActionAreaStyle: { display: 'flex', width: '100%', padding: '0px 10px' },
    cardContentFlexGrow: { flexGrow: 1 },
    iconWhiteColor: { color: '#fff' },
    categoryHeaderStyles: {
        fontSize: 16,
        color: "#fff",
        fontWeight: '550',
        fontFamily: 'Manrope',
        wordWrap: 'break-word',
    },
    descriptionStyles: {
        fontSize: 13,
        color: "#fff",
        fontWeight: '500',
        fontFamily: 'Manrope',
        wordWrap: 'break-word',
    },
    textFieldStyle: {
        background: '#202020',
        borderRadius: 15,
        border: '0.50px #48484D solid',
        justifyContent: 'flex-start',
        alignItems: 'center',
        fontFamily: "Manrope",
        fontWeight: "normal",
        flexGrow: 1,
    },
    textFieldMarginZeroStyle: {
        marginBottom: 0,
        padding: 2,
    },
    textFieldSx: {
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: 'transparent',
            },
        },
    },
    textFieldInputPropsStyle: {
        color: 'white',
        lineHeight: '2',
        fontSize: '16px',
    },
    iconButtonColorWhiteStyle: { color: 'white' },
    buttonStyle: {
        minWidth: 50,
        minHeight: 50,
        background: '#2F98A3',
        borderRadius: "20%",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 8,
    },
    buttonFullWidthStyle: {
        width: '100%',
        borderRadius: '10px',
        textTransform: 'none',
        fontSize: '18px',
        color: 'white',
    },
    inputFieldGridStyle: {
        boxSizing: 'border-box',
        width: '100%',
        padding: 8,
        background: '#121214',
        justifyContent: 'center',
        alignItems: 'left',
        display: 'flex',
        flexDirection: 'column',
    },
    gridItemStyle: {
        display: 'flex',
        justifyContent: 'start',
        textAlign: 'left',
    },
    gridItemCenterStyle: {
        display: 'flex',
        justifyContent: 'center',
        padding: 15,
    },
    iconButtonStyle: {
        color: 'white',
        margin: '10px 0',
    },
    fileNameTextStyle: {
        color: 'white',
        marginLeft: '10px',
        display: 'flex',
        alignItems: 'center',
    },
    closeButtonStyle: {
        color: 'white',
        marginLeft: '5px',
    },
    typographyCategoryHeader: {
        color: "#fff",
        fontFamily: 'Manrope',
    },
    menuPaperStyle: {
        backgroundColor: '#202020',
        color: '#fff',
        borderRadius: '8px',
    },
    menuItemStyle: {
        marginRight: '8px',
        color: '#fff',
    },
    menuItemDeleteStyle: {
        color: 'red',
    },
    menuItemDeleteIconStyle: {
        marginRight: '8px',
        color: 'red',
    },
    flexCenterStyle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    gridItemFlexColumnStyle: {
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'hidden',
        paddingBottom: 'env(safe-area-inset-bottom)',
    },
    topBarGridContainerStyle: {
        alignItems: "center",
        justifyContent: "space-between",
        overflow: "hidden",
    },
    topBarSubGridConatainerStyle:{
        padding: '10px', 
        display: 'flex', 
        justifyContent: 'center' 
    },
    iconButtonBackStyle: {
        color: 'white',
    },
    boxStyleMt3: { mt: 3 },
    boxStyleFileItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '10px',
        cursor: 'pointer',
    },
    circularProgressStyle: { color: '#2F98A3' },
    gridInputFieldStyle: {
        paddingBottom: `env(safe-area-inset-bottom, 15px)`,
    },
};

export const buttonSaveStyle = (isSubmitting) => ({
  ...styles.buttonStyle,
  ...styles.buttonFullWidthStyle,
  backgroundColor: isSubmitting ? '#B1B1B1' : '#2F98A3',
});