import React, { Component } from 'react';
import { Typography, Button, Modal } from '@mui/material';
import { AppContext } from '../AppContext';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { signUpStyles as styles } from './LoginStyles';

class SignUp extends Component {
  static contextType = AppContext;
  state = {
    showPopup: false,
  };

  handleSignUpClick = () => {
    this.setState({ showPopup: true });
  };

  handleClosePopup = () => {
    this.setState({ showPopup: false });
  };

  handleWebsiteClick = () => {
    window.open('https://blckbx.co.uk/', '_blank', 'noopener,noreferrer');
  };

  handleJoinClick = () => {
    window.open('https://join.blckbx.co.uk/', '_blank', 'noopener,noreferrer');
  };


  render() {
    const bulletPoints = [
      'Your own dedicated real life personal assistant',
      'Unlimited tasks',
      'Your whole household can use it',
      'Personalised recommendations',
      'Your to do lists and weekly reports',
      'Personalised ideas and reminders',
    ];

    return (
      <div style={{...styles.container,
        height:'80vh'
      }}>
        <div style={styles.scrollableContent}>

          <div style={styles.contentSection}>
            <Typography component="div" style={styles.heading}>
              How it works
            </Typography>

            <Typography component="div" style={styles.description}>
              We are a personal assistance service for busy, time-poor employees juggling their professional and personal lives. Your own dedicated real personal assistant will save you on average up to 30 hrs per month of your precious time by managing all of your to-dos outside work and coordinating your personal, home, and family tasks.
            </Typography>

            <div style={styles.bulletContainer}>
              {bulletPoints.map((point, index) => (
                <div key={index} style={styles.bulletItem}>
                  <CheckIcon style={styles.bulletIcon} />
                  <Typography variant="body2" style={styles.bulletText}>
                    {point}
                  </Typography>
                </div>
              ))}
            </div>

            {/* <Typography component="div" style={styles.priceText}>
              £249 +VAT per month
            </Typography> */}

            <div style={styles.buttonContainer}>
              <Button style={styles.submitButton} onClick={this.handleSignUpClick}>
                Sign Up
              </Button>
            </div>

            <Typography component="div" style={styles.footerText}>
              Want to read more?{' '}
              <span
                onClick={this.handleWebsiteClick}
                style={styles.underlineLink}
              >
                Visit our Website
              </span>
            </Typography>
          </div>
        </div>

        {/* Modal */}
        <Modal
          open={this.state.showPopup}
          onClose={this.handleClosePopup}
          style={styles.modal}
        >
          <div style={styles.modalOverlay} onClick={this.handleClosePopup}>
            <div style={styles.modalContent} onClick={(e) => e.stopPropagation()}>
              <div style={styles.popupHeader}>
                <Typography style={styles.heading}>
                  Sign Up
                </Typography>
                <CloseIcon style={styles.closeIcon} onClick={this.handleClosePopup} />
              </div>
              <Typography variant="body2" style={styles.modalDescription}>
                You can find out more and sign up to use our subscription service by completing a short form on our website.
              </Typography>

              <Typography variant="body2" style={styles.modalDescription}>
                You will receive your unique ID code which you can use to log back in here on the app.
              </Typography>
              <Button style={styles.modalButton} onClick={this.handleJoinClick}>
                Continue to sign up on website
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default SignUp;
