import React from 'react';
import { Typography, Tabs, Tab, Box, Avatar,IconButton} from "@mui/material";
import TaskCard from './TaskCard.js';
import AddTaskIcon from '@mui/icons-material/AddTask';
import { AppContext } from './AppContext.js';


const baseURL = process.env.REACT_APP_API_BASE_URL;
const styles = {
  priorityText: {
    display: "flex",
    justifyContent: "Left",
    alignItems: "center",
    color: "#000000",
    paddingTop: "12px",
    fontSize: 16,
    fontWeight: 400,
    marginTop:'15px'
  },
  header: {
    color: '#1C1D1F',
    textAlign: 'left',
    fontSize: '32px',
    fontWeight: '400',
    fontFamily: "'EB Garamond', serif",
    width: '100%',
  },
  offlineBanner: {
    width: "100%",
    textAlign: 'center',
    color: 'white',
    fontSize: 16,
    fontFamily: 'Manrope',
    fontWeight: '600',
    wordWrap: 'break-word',
    backgroundColor: 'red',
    padding: '10px 0'
  },
  tabContent: {
    padding: '0px 16px',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginTop: '30px',
    marginBottom: '8px',
  },
  subtitle: {
    color: '#1C1D1F',
    textAlign: 'left',
    fontSize: '14px',
    fontWeight: '400',
    fontFamily: "'Open Sans', sans-serif"
  },
  updatedStyles: {
    marginTop:'10px',
    marginBottom:'20px',
    color: '#555556',
    textAlign: 'left',
    fontSize: '14px',
    fontWeight: '400',
    fontFamily: "'Open Sans', sans-serif"
  },
  headerImage: {
    width: '60px',
    height: '60px',
    borderRadius: '15px', 
    objectFit: 'cover',
    marginLeft: '16px',
  },
  avatar: {
    width: '50px', 
    height: '50px',
    backgroundColor: '#898787', 
    fontSize: '20px',
  },
  avatarLarge: {
    width: '60px', // Increased width
    height: '60px', // Increased height
    backgroundColor: '#898787',
    fontSize: '20px',
  },
  suggestedTasksContainer: {
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'column',
    gap: '14px',
  },
  suggestedTaskBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '6px 10px',
    backgroundColor: '#fff',
    borderRadius: '8px',
  },
  suggestedTaskText: {
    display: 'flex',
    flexDirection: 'column',
  },
  suggestedTaskTitle: {
    marginLeft:'4px',
    fontSize: '14px',
    fontWeight: '600',
    letterSpacing:'0.2px',
    fontFamily: "'Open Sans', sans-serif"
  },
  suggestedTaskDescription: {
    fontSize: '12px',
    color: '#555555',
    letterSpacing:'0.1px',
    fontFamily: "'Open Sans', sans-serif"
  },
  avatarWrapper: {
    padding: '6px',
    background: '#555556',
    borderRadius: '10px',
  },
  noTasksMessage: {
    width:'100%',
    fontSize: '14px',
    color: '#000000',
    marginTop: '10px',
    fontFamily: "'Open Sans', sans-serif",
    background:'#F5F3F0',
    padding:'14px 16px',
    borderRadius:'8px',
  },
  savingToDateText: {
    display: "flex",
    justifyContent: "Left",
    alignItems: "center",
    color: "#000000",
    paddingTop: "12px",
    fontSize: 16,
    fontWeight: 400,
    marginTop:'15px',
    paddingBottom: '2px',
  },
  statsContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'stretch',
    padding: '6px 16px',
  },
  statBox: {
    textAlign: 'center',
    padding: '12px 5px',
    borderRadius: '20px',
    backgroundColor: '#E1DCD4',
    flex: 1,
    margin: '0 8px',
    display: 'flex', 
    flexDirection: 'column', 
    justifyContent: 'center',
  },
  statIconContainer: {
    textAlign: 'center',
    padding: '10px',
    borderRadius: '50%',
    backgroundColor: '#F8F8E4',
    margin: '0 auto',
    width: '65px', 
    height: '65px',
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center',
  },
  statLabel: {
    fontSize: '14px',
    color: '#555555',
    marginTop:'10px', 
    fontFamily: "'Open Sans', sans-serif", 
    padding:'0px 2px',
  },
  tabsStyles: {
    '& .MuiTabs-indicator': {
      backgroundColor: 'black',
    },
  },
  tabStyles: {
    textTransform: 'none',
    fontSize: '16px',
    fontFamily: "'Open Sans', sans-serif",
    color:'#1C1D1F',
    "&.Mui-selected": {
      fontWeight: 600,
    },
    "&:not(.Mui-selected)": {
      color: "rgba(0, 0, 0, 0.40)",
    },
  },
};

class ForYou extends React.Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0, // 0 for "For You", 1 for "Notes"
      moneySaved:0,
    };
  }

  componentDidMount(){
    this.fetchSavingMetrics()
  }

  fetchSavingMetrics = ()=>{

    const familyID = localStorage.getItem("familyID");

    const requestOptions = {
        method: "POST", headers: {
            "Content-type": "application/json; charset=UTF-8",
        }, body: JSON.stringify({
          familyID: familyID
        }),
    };

    fetch(`${baseURL}/utils/fetchSavingMetrics.php`, requestOptions)
        .then((response) => response.json())
        .then(data => {
        
          this.setState({
            moneySaved:data.totalSaved
          })    
        })
        .catch(error => console.error('Error fetching member name:', error));

  }

  handleTabChange = (event, newValue) => {
    this.setState({ selectedTab: newValue });
  };

  getInitials = (name) => {
    if (!name) return '';
    const nameParts = name.trim().split(' ');
    const initials = nameParts.map(part => part[0].toUpperCase()).join('');
    return initials.slice(0, 2); //first two initials
  };

  getLatestModifiedDate = (items, doneItems) => {
    const allItems = [...items, ...doneItems];
    let latestDate = null;

    allItems.forEach(item => {
      if (item.fields && item.fields['Last Modified']) {
        const itemDate = new Date(item.fields['Last Modified']);
        if (!latestDate || itemDate > latestDate) {
          latestDate = itemDate;
        }
      }
    });

    return latestDate;
  };


  handleSuggestedTaskClick =(title)=>{
    this.context.navigate(`/newTask?taskText=${encodeURIComponent(title)}`);

  }

formatDate = (date) => {
  if (!date) return 'N/A';

  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);

  const compareDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
  const currentDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
  const yesterdayDate = new Date(yesterday.getFullYear(), yesterday.getMonth(), yesterday.getDate());

  if (compareDate.getTime() === currentDate.getTime()) {
    return `Today at ${date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
  } else if (compareDate.getTime() === yesterdayDate.getTime()) {
    return `Yesterday at ${date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
  } else {
    return date.toLocaleDateString('default', { 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric' 
    });
  }
};

renderStats() {

  const {moneySaved}= this.state
  const { doneItems } = this.context;

  const completedTasksCount = doneItems.length

  return (
    <>
      <Typography style={styles.savingToDateText}>
        Saving to date
      </Typography>
      <Box style={styles.statsContainer}>
        {/* Common styles for all stat boxes */}
        {[
          // { to be added when we do time saved
          //   icon: './app-icons/Hours.svg',
          //   label: '44 Hours Time freed',
          // },
          {
            icon: './app-icons/Save.svg',
            label: `£${moneySaved} Money saved`,
          },
          {
            icon: './app-icons/Off-loaded.svg',
            label: `${completedTasksCount} tasks Offloaded`,
          },
        ].map((stat, index) => (
          <Box
            key={index}
            style={styles.statBox}
          >
            <Box style={styles.statIconContainer}>
              <img
                src={stat.icon}
                alt={stat.label}
              />
            </Box>
           
            <Typography style={styles.statLabel}>
              {stat.label}
            </Typography>
          </Box>
        ))}
      </Box>
    </>
  );
}


  renderForYouContent() {
    const { items, unreadMessageCount, isOnline, handleDeleteTask, doneItems, members } = this.context;
    const {
      getItemStyle,
      handleTaskClick,
      handlePin,
      handleRemovePin,
      handleHold,
      handleUnhold,
      handleChildStateChange,
      calculatePinnedCount,
      maxActiveTasks,
    } = this.props;

    const latestModifiedDate = this.getLatestModifiedDate(items, doneItems);
    const formattedLatestDate = this.formatDate(latestModifiedDate);
 
    const twoWeeksAgo = new Date();
    twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 7);

    const recentDoneItems = doneItems.filter(item => {
      if (!item.fields || !item.fields['Closed Date']) return false;
    
      const closedDateString = item.fields['Closed Date'];
      const [datePart, timePart] = closedDateString.split(' ');
    
      const [day, month, year] = datePart.split('/').map(Number);
      const [hour, minute] = timePart.split(':').map(Number);
    
      const closedDate = new Date(Date.UTC(year, month - 1, day, hour, minute));
    
      return closedDate >= twoWeeksAgo;
    });
    

    const memberID = localStorage.getItem("memberID")

    const member = members.find(m => m.ID === memberID) || {};
    const firstName = member.Name ? member.Name.split(' ')[0] : '';

    const infoNeededItems = items.filter(
      item => item.fields && item.fields['Task - Status Text'][0] === 'Info Needed'
    );
    const pinnedItems = items.filter(
      item => item.fields['Task - Status Text'][0] === 'In Progress' && item.fields['Task - Status Text'][0] !== 'Info Needed'
    );
    
    const tasksPinnedCount = calculatePinnedCount(items);

    const initials = this.getInitials(member.Name);

    const profilePic = null;

    const suggestedTasks = [
      {
        id: 'suggested-1',
        title: 'Send weekly meal plan ideas',  
      },
      {
        id: 'suggested-2',
        title: 'Give me ideas of things to do this month',
      },
      {
        id: 'suggested-3',
        title: 'Help me plan Christmas',
      },
    ];

    return (
      <div>
        {!isOnline && (
          <div style={styles.offlineBanner}>
            You're offline. Failed to Fetch Tasks
          </div>
        )}

        <div style={styles.tabContent}>
          <Box style={styles.headerContainer}>
            <div>
              <Typography style={styles.header}>Hello, {firstName}</Typography>
              {/* <Typography style={{...styles.subtitle}}>Here’s your personalised highlights</Typography> */}
        
            </div>

            {profilePic ? (
              <Avatar
                src={profilePic}
                alt="Profile"
                style={styles.avatarLarge}
                variant="rounded"
              />
            ) : (
              <div style={styles.avatarWrapper}>
                <Avatar
                  alt="Profile"
                  style={styles.avatar}
                >
                  {initials}
                </Avatar>
              </div>
            )}

          </Box>

          {items.length <= 3 && (
          <div className='SuggestedTasks' style={styles.suggestedTasksContainer}>
            <Typography style={styles.priorityText}>Popular tasks others are requesting now</Typography>
            {suggestedTasks.map(task => (
              <Box key={task.id} style={styles.suggestedTaskBox} onClick={()=>{this.handleSuggestedTaskClick(task.title)}}>
              <div style={styles.suggestedTaskText}>
                <Typography style={styles.suggestedTaskTitle}>
                  {task.title}
                </Typography>
                {/* <Typography style={styles.suggestedTaskDescription}>
                  {task.description}
                </Typography> */}
              </div>
              <IconButton
                color="primary"
                onClick={(event) => {
                  event.stopPropagation();
                  this.handleSuggestedTaskClick(task.title);
                }}
              >
                <AddTaskIcon sx={{color:'black'}} />
              </IconButton>
            </Box>
            ))}
          </div>
        )}

          {/* Render Info Needed Tasks or "All Up to Date" Message */}
        <div className='InfoNeeded'>
          <div style={styles.priorityText}>Waiting on you</div>
          {infoNeededItems.length > 0 ? (
           infoNeededItems.map((item) => {
            const unholdItems = items.filter((item) => !item.isHold);
            const index = unholdItems.findIndex(i => i.id === item.id);
            return (
              <div
                key={item.id}
                style={getItemStyle()}
                onClick={() => handleTaskClick(item)}
              >
                <TaskCard
                  index={index}
                  chatTask={item}
                  unreadMessageCount={unreadMessageCount[item.id]}
                  showControls={true}
                  isItemPinned={item.isPinned}
                  handlePin={handlePin}
                  handleRemovePin={handleRemovePin}
                  maxPinnedItems={maxActiveTasks}
                  currentPinnedCount={tasksPinnedCount}
                  onDeleteTask={handleDeleteTask}
                  isItemHold={item.isHold}
                  handleHold={handleHold}
                  handleUnhold={handleUnhold}
                  onChildStateChange={handleChildStateChange}
                />
              </div>
            );
          })
          ) : (
            <Typography style={styles.noTasksMessage}>
              You have no tasks waiting on you
            </Typography>
          )}
        </div>

          {/* Render Pinned Tasks */}
          {pinnedItems.length > 0 && (
            <div className='WorkingOn'>
              <div style={styles.priorityText}>In progress</div>
              {pinnedItems.map((item) => {
                const unholdItems = items.filter((item) => !item.isHold);
                const index = unholdItems.findIndex(i => i.id === item.id);
                return (
                  <div
                    key={item.id}
                    style={getItemStyle()}
                    onClick={() => handleTaskClick(item)}
                  >
                    <TaskCard
                      index={index}
                      chatTask={item}
                      unreadMessageCount={unreadMessageCount[item.id]}
                      showControls={true}
                      isItemPinned={item.isPinned}
                      handlePin={handlePin}
                      handleRemovePin={handleRemovePin}
                      maxPinnedItems={maxActiveTasks}
                      currentPinnedCount={tasksPinnedCount}
                      onDeleteTask={handleDeleteTask}
                      isItemHold={item.isHold}
                      handleHold={handleHold}
                      handleUnhold={handleUnhold}
                      onChildStateChange={handleChildStateChange}
                    />
                  </div>
                );
              })}
            </div>
          )}

            {/* Render Completed this week */}
            {recentDoneItems.length > 0 && (
            <div>
              <div style={styles.priorityText}>Completed this week</div>
              {recentDoneItems.map((item, index) => (
                <div
                  key={item.id}
                  style={getItemStyle()}
                  onClick={() => handleTaskClick(item)}
                >
                  <TaskCard
                    index={index}
                    chatTask={item}
                    unreadMessageCount={unreadMessageCount[item.id]}
                    showControls={true}
                    isItemPinned={item.isPinned}
                    handlePin={handlePin}
                    handleRemovePin={handleRemovePin}
                    maxPinnedItems={maxActiveTasks}
                    currentPinnedCount={tasksPinnedCount}
                    onDeleteTask={handleDeleteTask}
                    isItemHold={item.isHold}
                    handleHold={handleHold}
                    handleUnhold={handleUnhold}
                    onChildStateChange={handleChildStateChange}
                  />
                </div>
              ))}
            </div>
          )}
            {this.renderStats()}
            <Typography style={styles.updatedStyles}>Last Updated:{formattedLatestDate}</Typography>
        </div>
      </div>
    );
  }

  //for when we do notes 
  // renderNotesContent() {
  //   return (
  //     <Box p={3}>
  //       {/* Notes content can be added here in the future */}
  //       {/* Currently left empty as per the requirement */}
  //     </Box>
  //   );
  // }

  render() {
    const { selectedTab } = this.state;

    return (
      <div>
        {/* Tabs at the top */}
        <Tabs
          value={selectedTab}
          onChange={this.handleTabChange}
          textColor="black"
          aria-label="task tabs"
          centered
          sx={styles.tabsStyles}
        >
          <Tab 
          label="For  You" 
          sx={styles.tabStyles}/>
           {/* Notes tab */}
          {/* <Tab label="Notes" 
           sx={{
            textTransform: 'none',
            fontSize: '16px',
            fontFamily: 'Manrope',
            "&.Mui-selected": {
                fontWeight: 600
            },
            "&:not(.Mui-selected)": {
                color: "rgba(0, 0, 0, 0.40)",
            },
        }}/> */}
        </Tabs>

        {/* Tab Panels */}
        {selectedTab === 0 && this.renderForYouContent()}
        {/* Notes render function */}
        {/* {selectedTab === 1 && this.renderNotesContent()} */}
      </div>
    );
  }
}

export default ForYou;
