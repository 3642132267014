import React from 'react';
import {Dialog,DialogTitle,DialogContent,DialogActions,Button,TextField,InputAdornment,IconButton, Typography } from "@mui/material";
import { Visibility, VisibilityOff } from '@mui/icons-material';

const secureURL = process.env.REACT_APP_SECURE_URL;
const secureKey = process.env.REACT_APP_UPLOAD_API_KEY;
class EditDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editDynamicFields: {},
            editContent: '',
            isLoading: true,
            showPasswordFields: {},
        };
    }

    componentDidMount() {
        if (this.props.isOpen) {
            this.fetchFileContent();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.isOpen && !prevProps.isOpen) {
            this.fetchFileContent();
        }
    }

    fetchFileContent = async () => {
        const { fileToEdit, category, subCategory } = this.props;
        const familyId = localStorage.getItem('familyID');
        const url = `${secureURL}/fileupload/digiLocker.php?filename=${fileToEdit}&familyid=${familyId}&category=${category.name}&subcategory=${subCategory}`;

        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${secureKey}`,
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const content = await response.text();

            let jsonData = {};
            try {
                jsonData = JSON.parse(content);
            } catch (e) {
                console.error('Error parsing JSON content:', e);
            }

            jsonData.textField = JSON.parse(jsonData.textField);

            this.setState({
                editDynamicFields: jsonData.textField || {},
                editContent: content,
                isLoading: false,
            });
        } catch (error) {
            console.error('Error fetching file for editing:', error);
            alert('Failed to fetch file for editing.');
            this.setState({ isLoading: false });
            this.props.onClose();
        }
    };

    handleEditFieldChange = (fieldName, value) => {
        this.setState(prevState => ({
            editDynamicFields: {
                ...prevState.editDynamicFields,
                [fieldName]: value
            }
        }));
    };

    handleContentChange = (e) => {
        this.setState({ editContent: e.target.value });
    };

    handleSaveEdit = async () => {
        const { fileToEdit, category, subCategory, onSaveSuccess } = this.props;
        const familyId = localStorage.getItem('familyID');
        const { editDynamicFields, editContent } = this.state;

        let textfield;
        if (Object.keys(editDynamicFields).length > 0) {
            textfield = JSON.stringify(editDynamicFields);
        } else {
            textfield = editContent;
        }

        const data = {
            familyid: familyId,
            category: category.name,
            subcategory: subCategory,
            filename: fileToEdit,
            newValue: textfield,
        };

        try {
            const response = await fetch(`${secureURL}/fileupload/digiLocker.php`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${secureKey}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            const result = await response.json();

            if (result.success) {
                this.setState({ editDynamicFields: {}, editContent: '' });
                this.props.onClose();
                if (onSaveSuccess) {
                    onSaveSuccess(); // Notify parent to refresh the files list
                }
            } else {
                console.error('Error saving edited file:', result.message);
                alert('Failed to save changes.');
            }
        } catch (error) {
            console.error('Error saving edited file:', error);
            alert('Failed to save changes.');
        }
    };

    handleTogglePasswordVisibility = (fieldName) => {
        this.setState(prevState => ({
            showPasswordFields: {
                ...prevState.showPasswordFields,
                [fieldName]: !prevState.showPasswordFields[fieldName],
            }
        }));
    };

    render() {
        const { isOpen, onClose, subCategory, getDynamicFields } = this.props;
        const { editDynamicFields, editContent, isLoading, showPasswordFields } = this.state;

        const inputFields = getDynamicFields(subCategory);
        const textFieldStyle = {
            background: '#fff',
            borderRadius: '8px',
            justifyContent: 'flex-start',
            alignItems: 'center',
             fontFamily: "'Open Sans', sans-serif",
            fontWeight: "normal",
            flexGrow: 1,
        };

        return (
            <Dialog
                open={isOpen}
                onClose={onClose}
                PaperProps={{
                    style: { backgroundColor: '#E8E5E0', color: '#1C1D1F', borderRadius: '15px',  fontFamily: "'Open Sans', sans-serif" },
                }}
                fullWidth
                maxWidth="md"
            >
                <DialogTitle>Edit Details</DialogTitle>
                <DialogContent>
                    {isLoading ? (
                        <div>Loading...</div>
                    ) : Object.keys(editDynamicFields).length > 0 ? (
                        inputFields.map((field, index) => {
                            const isPassword = field.type === 'password';
                            const isVisible = showPasswordFields[field.name] || false;
                            return (
                                <div key={index} style={{ marginBottom: '16px' }}>
                                <Typography style={{ color: '#1C1D1F',marginBottom:'8px',fontWeight: 'bold' }}>
                                    {field.label}
                                </Typography>
                                <TextField
                                    name={field.name}
                                    placeholder={field.label}
                                    type={isPassword ? (isVisible ? 'text' : 'password') : field.type}
                                    value={editDynamicFields[field.name] || ''}
                                    onChange={(e) => this.handleEditFieldChange(field.name, e.target.value)}
                                    fullWidth
                                    style={textFieldStyle}
                                    size="small"
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                border: 'none',
                                            },
                                            '&:hover fieldset': {
                                                border: 'none',
                                            },
                                            '&.Mui-focused fieldset': {
                                                border: 'none',
                                            },
                                        },
                                    }}
                                    InputProps={{
                                        endAdornment: isPassword && (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => this.handleTogglePasswordVisibility(field.name)}
                                                    edge="end"
                                                    style={{ color: '#1C1D1F' }}
                                                >
                                                    {isVisible ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                        style: {
                                            color: '#1C1D1F',
                                            lineHeight: '2',
                                            fontSize: '18px',
                                        },
                                    }}
                                />
                            </div>
                            );
                        })
                    ) : (
                                <div style={{ marginBottom: '16px' }}>
                                    <Typography variant="body1" style={{ marginBottom: '8px', color: '#1C1D1F', fontWeight: 'bold' }}>
                                        Content
                                    </Typography>
                                    <TextField
                                        value={editContent}
                                        onChange={this.handleContentChange}
                                        multiline
                                        fullWidth
                                        variant="outlined"
                                        InputProps={{
                                            style: {
                                                color: '#1C1D1F',
                                            },
                                        }}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    border: 'none',
                                                },
                                                '&:hover fieldset': {
                                                    border: 'none',
                                                },
                                                '&.Mui-focused fieldset': {
                                                    border: 'none',
                                                },
                                            },
                                        }}
                                    />
                                </div>

                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={onClose}
                        style={{ color: '#1C1D1F' }}
                    >
                        Cancel
                    </Button>
                    <Button onClick={this.handleSaveEdit} style={{ color: '#1C1D1F' }}>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default EditDialog;
