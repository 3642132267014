import React, {Component} from 'react'
import {Grid, TextField, Button, IconButton} from '@mui/material'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import { ChevronLeft } from '@mui/icons-material';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import CloseIcon from '@mui/icons-material/Close';

class ComposeChatDialog extends Component {

    constructor(props) {
        super(props)
        this.state = {
            open: this.props.isComposeChatOpen || false, userMessage: this.props.userMessage || '',
        }

        this.topSection = React.createRef();
        this.filePreview = React.createRef();
        this.inputField = React.createRef();

    }

    componentDidMount() {
        this.setState({
            userMessage: this.props.userMessage || '',
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.isComposeChatOpen !== this.props.isComposeChatOpen) {
            this.setState({open: this.props.isComposeChatOpen});
        }
    }

    render() {

        const {fileName, fileType, fileSize, filePreview, fileTypeFormatted, image, video} = this.props

        const document = [
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.ms-powerpoint',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation',
            'text/plain',
            'application/rtf',
            'text/csv',
            'text/vcard',
            'application/zip',
            'application/x-zip-compressed',
            'application/pdf', 
          ];

        const styles = {
            composeChatContainer: {
                position: 'fixed',
                bottom: 0,
                left: 0,
                top: 0,
                right: 0,
                backgroundColor: '#E8E5E0',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                overflow: 'hidden',
                margin: 0,
                paddingLeft: 12,
                paddingRight: 12,
                paddingTop: 'calc(12px + env(safe-area-inset-top))', 
                paddingBottom: 'calc(12px + env(safe-area-inset-bottom))',
                zIndex: 9999999999,
                overflowY: 'auto',
                width: '100%',
            }, closeIcon: {
                color: 'white', margin: '0 12px 0 12px', paddingRight: 12,
            }, textFieldContainer: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                backgroundColor: '#E8E5E0',
                zIndex: 9999999999,
                padding: '2px 0 0 0',
            }, textField: {
                background: '#FFFFFF',
                borderRadius: 8,
                overflow: 'hidden',
                display: 'flex',
                justifyContent: 'center',
                fontFamily: "'Open Sans', sans-serif",
                fontWeight: "normal",
                fontSize: "clamp(0.6rem, 2vw, 1rem)",
                width: '100%',
            }, submitButton: {
                minWidth: 48,
                minHeight: 48,
                borderRadius: "50%",
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginLeft: 4,
            }, fileName: {
                textAlign: 'left',
                color: '#1C1D1F',
                fontSize: 16,
                fontFamily: "'Open Sans', sans-serif",
                fontWeight: '600',
                wordWrap: 'break-word',
                padding: 12,
                width: '100%',
                display: 'flex',
                flexGrow: 1,
            }, filePreviewContainer: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: 12,
                width: '50%',
                height: 'auto',
            }, filePreviewImage: {
                width: '100%',
                height: 'auto',
                borderRadius: 20,
                padding: 12,
            }, filePreviewVideo: {
                maxWidth: 300,
                height: 'auto',
                borderRadius: 20,
                padding: 12,
            }, filePreviewDocument: {
                width: '100%',
                minWidth: 350,
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#D9D9D9',
                borderRadius: 15,
                padding: 12,
            }, filePreviewDetails: {
                textAlign: 'left',
                color: 'white',
                fontSize: 16,
                fontFamily: 'Manrope',
                fontWeight: 'light',
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
            }, filePreviewFileName: {
                color: '#000000', fontSize: 16,
            }, filePreviewFileType: {
                color: '#555556', fontSize: 12, marginRight: 6,
            }, filePreviewFileSize: {
                color: '#555556', fontSize: 12,
            },
        };

        return (<>
            <div style={styles.composeChatContainer}>

                {/* Top Section */}
                <Grid
                    ref={this.topSection}
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    style={{ marginBottom: '12px' }}
                >
                    {/* Left: ChevronLeft Icon */}
                    <Grid item xs={2} sm={1} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <IconButton onClick={() => this.props.onClose()}>
                            <ChevronLeft sx={{color:'black'}} />
                        </IconButton>
                    </Grid>

                    {/* Center: File Name */}
                    <Grid item xs={8} sm={10} style={{ display: 'flex', justifyContent: 'center' }}>
                        <div style={styles.fileName}>
                            {fileName}
                        </div>
                    </Grid>

                    {/* Right: Close Icon */}
                    <Grid item xs={2} sm={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <IconButton onClick={() => this.props.onClose()} >
                            <CloseIcon  sx={{color:'black'}} />
                        </IconButton>
                    </Grid>
                </Grid>


                {/* File Preview */}
                <Grid ref={this.filePreview} style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: '12px'
                }}>

                    <div style={styles.filePreviewContainer}>
                        {(() => {

                            if (image.includes(fileType)) {
                                return <img src={filePreview} alt={fileName} style={styles.filePreviewImage}/>;
                            } else if (video.includes(fileType)) {
                                return <video controls src={filePreview} style={styles.filePreviewVideo}/>;
                            } else if (document.includes(fileType)) {
                                let icon = <img src='./app-icons/document.svg' alt='Document Icon' 
                                                style={{width: 70, marginRight: 12, filter: 'invert(1)'}}/>;
                                if (fileTypeFormatted === 'PDF') {
                                    icon = <PictureAsPdfIcon style={{color: 'black', fontSize: 70, marginRight: 12}}/>;
                                } else if (fileTypeFormatted === 'ZIP') {
                                    icon = <FolderZipIcon style={{color: 'black', fontSize: 70, marginRight: 12}}/>;
                                }
                                return (<div style={styles.filePreviewDocument}>
                                    {icon}
                                    <div style={styles.filePreviewDetails}>
                                        <span style={styles.filePreviewFileName}>{fileName}</span>
                                        <div style={{gap: 5}}>
                                            <span style={styles.filePreviewFileType}>{fileTypeFormatted}</span>
                                            <span style={{marginRight: 6}}>&middot;</span>
                                            <span style={styles.filePreviewFileSize}>{fileSize}</span>
                                        </div>
                                    </div>
                                </div>);
                            }
                        })()}
                    </div>
                </Grid>

                {/* Input Field */}
                <Grid container alignItems="center" justifyContent="space-between">
                    <div ref={this.inputField} style={styles.textFieldContainer}>

                        <TextField
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                   
                                    '& fieldset': {
                                        border: 'none',
                                    },
                                  
                                    '&:hover fieldset': {
                                        border: 'none',
                                    },
                                
                                    '&.Mui-focused fieldset': {
                                        border: 'none',
                                    },
                                },
                            }}
                            style={styles.textField}
                            placeholder="Add a caption"
                            value={this.state.userMessage}
                            multiline
                            size='small'
                            variant='outlined'
                            inputProps={{style: {color: '#1C1D1F'}}}
                            onChange={(e) => this.setState({userMessage: e.target.value})}
                            onKeyDown={(e) => {

                                if (e.key === 'Enter' && this.state.userMessage !== '') {
                                    e.preventDefault();

                                    this.props.sendMessage(this.state.userMessage)
                                    this.props.onClose();
                                }
                            }}
                        />

                        <Button
                            style={styles.submitButton}
                            type="submit"
                            onClick={() => {
                                this.props.sendMessage(this.state.userMessage)
                                this.props.onClose();
                            }}>
                            <ArrowCircleRightOutlinedIcon sx={{fontSize:'32px', color:'black'}}/>
                        </Button>
                    </div>

                </Grid>
            </div>
        </>)
    }
}

export default ComposeChatDialog