import React from 'react';
import {
    Typography, Grid, Button, Box,
} from "@mui/material";
import {AppContext} from './AppContext';
import {Link} from 'react-router-dom';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import "../styles/style.css";

const styles = {
    priorityText: {
      display: "flex",
      justifyContent: "Left",
      alignItems: "center",
      color: "#000000",
      paddingTop: "12px",
      fontSize: 16,
      fontWeight: 400,
      marginTop:'15px'
    },
    header: {
      color: '#1C1D1F',
      textAlign: 'left',
      fontSize: '24px',
      fontWeight: '400',
      fontFamily: "'EB Garamond', serif",
      width: '100%',
      marginBottom:'20px'
    },
    recommendationStyles: {
        backgroundColor: '#DBF2F0',
        padding: '10px',
        fontSize: '14px',
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: '400',
        lineHeight: '18px',
        color: '#1C1D1F',
        marginBottom: '20px', 
        cursor: 'pointer', 
    },
}
class TaskOverview extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            topBarHeight:null
           
        };
        this.topBar = React.createRef();

    }

    componentDidMount() {
        this.updateHeights();
        window.addEventListener('resize', this.updateHeights);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateHeights);
    }

    updateHeights = () => {
        const topBarHeight = this.topBar.current ? this.topBar.current.clientHeight : 0;


        this.setState({
            topBarHeight,
        });
    }

    handleChatClick = () => {
        this.context.clearTaskBadges(this.context.chatTask.id).then(() => {
            this.context.navigate('/chat');
        });
    }

    handleRecommendationClick = () => {
        const { recLink } = this.context.chatTask;
        if (recLink) {
            window.open(recLink, '_blank');
        }
    }
   

    render() {
    
        const chatTask= this.context.chatTask

        const buttonStyle = {
            background: '#1C1D1F',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color:'white',
            textTransform: 'none',
            fontSize:'14px',
            padding:'10px  0px'

        }

        return (<>

            <div style={{
                display: 'flex',
                flexDirection: 'column',
                padding: 0,
                overflowY: 'hidden',
                paddingBottom: 'env(safe-area-inset-bottom)'
            }}>


                <Box>
                    <Grid container ref={this.topBar} alignItems="center" justifyContent="space-between"
                          overflow="hidden">
                        <Grid item xs={1} sm={1} style={{padding: '10px', display: 'flex', justifyContent: 'center'}}>
                            <Link to={`/#`} >
                               <ChevronLeftIcon style={{color: 'black'}}/>
                            </Link>
                        </Grid>
                        <Grid item xs={1} sm={1}></Grid>
                    </Grid>
                </Box>


                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    overflowY: 'auto',
                    height: `calc(100vh - ${(this.state.topBarHeight)}px)`,
                    padding:'20px'
                }}>

                    <Typography style={styles.header}>{chatTask.fields["Title"]}</Typography>
                    {chatTask.recLink && (
                        <div
                            style={styles.recommendationStyles}
                            onClick={this.handleRecommendationClick}
                        >
                            Recommendations have been added. Please confirm your preferred option.
                        </div>
                    )}
                   
                  
                        <Button style={buttonStyle}  onClick={()=>{this.handleChatClick()}}>
                           <ChatOutlinedIcon style={{marginRight:'5px'}}/> Chat about this
                        </Button>

                 

                </div>

            </div>
        </>)
    }
}

export default TaskOverview;